import * as bootstrap from "bootstrap"
import jquery from "jquery"
import validate from "jquery-validation"
import PdfMake from "pdfmake"
import DataTable from 'datatables.net-bs5'
import DataTableButtons from 'datatables.net-buttons-bs5'
import DataTableButtonsHTML from 'datatables.net-buttons/js/buttons.html5.js'
import Tags from "bootstrap5-tags";
import './address_copy.js' 
import Rails from "@rails/ujs"
// app/assets/javascripts/application.js
//= require popper
//= require bootstrap
//= require bootstrap-toggle
//= require select2
//= require jquery
//= require jquery_ujs
//= require activestorage

// app/javascript/packs/application.js
import * as ActiveStorage from "@rails/activestorage"
import { DirectUpload } from "@rails/activestorage"

ActiveStorage.start()


import moment from './moment.min.js';
import './select2.min.js';
import './daterangepicker.js';
import './customer_approval_details.js';
import './programs.js';
import './roles_and_permissions.js';
import './supplier_location.js';

// import "./channels";
Rails.start()

window.jQuery = jquery;
window.$ = jquery;
window.pdfmake = PdfMake;

// document.addEventListener("DOMContentLoaded", function() {
//   const TRUSTED_DOMAINS = ['localhost', 'meezan.tijarah-staging.fauree.com', 'bankislami.tijarah-staging.fauree.com', 'tijarah-staging.fauree.com'];
//   const url = window.location.href
//   safeRedirect(url)
//   function safeRedirect(url) {
//     if (isValidUrl(url) == false) {
//       console.error('Invalid URL:', url);
//     }
//   }
//   function isValidUrl(url) {
//     const parser = document.createElement('a');
//     parser.href = url;
//     return TRUSTED_DOMAINS.includes(parser.hostname) && parser.protocol !== 'javascript:';
//   }
// });


document.addEventListener("DOMContentLoaded", function() {
  var form = document.getElementById("imports");
  var fileInput = document.getElementById("imports_file");
  var maxFileSizeInBytes = parseFloat(document.getElementById("current-user-size-limit").getAttribute("data-size-limit"));
  var fileError = document.getElementById("fileError");
  fileInput.addEventListener("change", function(event) {
    var file = event.target.files[0];
    if (file) {
      if (file.size > maxFileSizeInBytes) {
        displayError("File size exceeds the maximum allowed size of " + formatBytes(maxFileSizeInBytes) + ".");
        clearFileInput();
      } else {
        clearError();
      }
    }
  });

  form.addEventListener("submit", function(event) {
    var file = fileInput.files[0];
    if (file && file.size > maxFileSizeInBytes) {
      event.preventDefault();
      displayError("File size exceeds the maximum allowed size of " + formatBytes(maxFileSizeInBytes) + ".");
      return false;
    } else {
      clearError();
    }
  });

  function formatBytes(bytes) {
    if (bytes == 0) return '0 Bytes';
    var k = 1024;
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    var i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  }

  function displayError(message) {
    fileError.textContent = message;
  }

  function clearError() {
    fileError.textContent = "";
  }

  function clearFileInput() {
    fileInput.value = "";
  }
});

// Initialize the Active Storage direct upload functionality
document.addEventListener("DOMContentLoaded", () => {
  const inputElements = document.querySelectorAll('input[type=file][data-direct-upload-url]')
  inputElements.forEach(inputElement => {
    const upload = new DirectUpload(inputElement.files[0], inputElement.dataset.directUploadUrl)
    upload.create((error, blob) => {
      if (error) {
        console.error(error)
      } else {
        console.log("Direct upload completed", blob.signed_id)
      }
    })
  })
})

document.addEventListener("DOMContentLoaded", function() {
  var modal = document.getElementById("approval_pending");
  var closeBtn = document.getElementById("closeModalBtn");
  var footerCloseBtn = document.getElementById("closeFooterBtn");

  // Display the modal
  modal.style.display = "block";

  // Close the modal when the close button is clicked
  closeBtn.onclick = function() {
    modal.style.display = "none";
  }

  // Close the modal when the footer close button is clicked
  footerCloseBtn.onclick = function() {
    modal.style.display = "none";
  }

  // Close the modal when the user clicks outside of it
  window.onclick = function(event) {
    if (event.target == modal) {
      modal.style.display = "none";
    }
  }
});

document.addEventListener("DOMContentLoaded", function() {
  const currencyFields = document.querySelectorAll(".currency-field");

  currencyFields.forEach(function(currencyField) {
    currencyField.addEventListener("input", function(event) {
      // Remove all non-numeric characters except commas and periods
      let value = event.target.value.replace(/[^\d,\.]/g, '');

      // If the value contains a period, split into integer and fractional parts
      if (value.includes(".")) {
        const parts = value.split(".");
        parts[0] = parts[0].replace(/,/g, ''); // Remove commas from the integer part
        parts[0] = formatCurrency(parts[0]); // Add commas to the integer part
        value = parts.join("."); // Join integer and fractional parts back together
      } else {
        value = value.replace(/,/g, ''); // Remove commas
        value = formatCurrency(value); // Add commas
      }

      event.target.value = value;
    });
  });

  function formatCurrency(value) {
    if (!value) return '';
    return value.replace(/\B(?=(\d{3})+(?!\d))/g, ','); // Add commas as thousands separators
  }
});

document.addEventListener('DOMContentLoaded', () => {
  $('.date-range-picker').daterangepicker({
    showDropdowns: true,
    autoUpdateInput: false,
    opens: 'center',
    locale: {
      format: 'DD/MM/YYYY',
    },
  });

  $('.date-range-picker').on('apply.daterangepicker', function(ev, picker) {
    $(this).val(picker.startDate.format('DD/MM/YYYY') + ' - ' + picker.endDate.format('DD/MM/YYYY'));
  });

  $('.date-range-picker').on('apply.daterangepicker', function(ev, picker) {
    $('#invoice-page-filter-form').submit();
  });
});


window.po_calculate = function(id) {
  const temp = id.split("purchase_order_items_attributes_")[1].split("_quantity")[0]

  const quantity_id = id
  const price_id = "purchase_order_items_attributes_" + temp + "_price"
  const total_id = "purchase_order_items_attributes_" + temp + "_total_amount"

  let val_1 = $("#" + quantity_id).val();
  let val_2 = $("#" + price_id).val();

  let total = val_1 * val_2
  $("#" + total_id).val(total);
}

window.po_calculate_total = function(id) {
  const temp = id.split("purchase_order_items_attributes_")[1].split("_price")[0]

  const quantity_id = id
  const price_id = "purchase_order_items_attributes_" + temp + "_quantity"
  const total_id = "purchase_order_items_attributes_" + temp + "_total_amount"

  let val_1 = $("#" + quantity_id).val();
  let val_2 = $("#" + price_id).val();

  let total = val_1 * val_2
  $("#" + total_id).val(total);
}

window.do_calculate = function(id) {
  const temp = id.split("delivery_order_items_attributes_")[1].split("_quantity")[0]

  const quantity_id = id
  const price_id = "delivery_order_items_attributes_" + temp + "_price"
  const total_id = "delivery_order_items_attributes_" + temp + "_total_amount"

  let val_1 = $("#" + quantity_id).val();
  let val_2 = $("#" + price_id).val();

  let total = val_1 * val_2
  $("#" + total_id).val(total);
}

window.do_calculate_total = function(id) {
  const temp = id.split("delivery_order_items_attributes_")[1].split("_price")[0]

  const quantity_id = id
  const price_id = "delivery_order_items_attributes_" + temp + "_quantity"
  const total_id = "delivery_order_items_attributes_" + temp + "_total_amount"

  let val_1 = $("#" + quantity_id).val();
  let val_2 = $("#" + price_id).val();

  let total = val_1 * val_2
  $("#" + total_id).val(total);
}



window.invoice_calculate = function(id) {
  const temp = id.split("invoice_items_attributes_")[1].split("_quantity")[0]

  const quantity_id = id
  const price_id = "invoice_items_attributes_" + temp + "_price"
  const total_id = "invoice_items_attributes_" + temp + "_total_amount"

  let val_1 = $("#" + quantity_id).val();
  let val_2 = $("#" + price_id).val();

  let total = val_1 * val_2
  $("#" + total_id).val(total);
}

window.invoice_calculate_total = function(id) {
  const temp = id.split("invoice_items_attributes_")[1].split("_price")[0]

  const quantity_id = id
  const price_id = "invoice_items_attributes_" + temp + "_quantity"
  const total_id = "invoice_items_attributes_" + temp + "_total_amount"

  let val_1 = $("#" + quantity_id).val();
  let val_2 = $("#" + price_id).val();

  let total = val_1 * val_2
  $("#" + total_id).val(total);
}

$('.reference_document_type').on("change", function(){
  $(this).parents('.fields').find('.select2-selection__rendered').text('');
  $(this).parents('.fields').find('.select2-item').val('');
});

function select2(){
  $('.select2').select2({
    minimumInputLength: 2,
    placeholder: "Select an option",
    allowClear: true,
    ajax: {
      url: function () {
        return "/"+$(this).parents('.fields').find('.reference_document_type').attr("data-request")+"/fetch_ref_doc_number.json";
      },
      dataType: 'json',
      quietMillis: 250,
      data: function (params) {
        var ref_doc_type = $(this).parents('.fields').find('select').val();
        return {
          search: params.term,//search term
          pagelimit: 1, // page size
          page: params.page, // page number
          type: ref_doc_type,
        };
      },
      processResults: function (data, params) {
        params.page = params.page || 1;
        var more = (params.page * 10) < data.count;
        return {
          results: data.reference_document_numbers,
          pagination: {
            more: more
          }
        }
      }
    }
  });
}

function select2_select(){
  $('.select2').on('select2:select', function (e) {
    var number = $(e.currentTarget).val();
    var nextSelectId = $(this).parents('.fields').find('.select2-item')[0].id;
    var SelectId = $("#"+nextSelectId);
    var ref_doc_type = $(this).parents('.fields').find('select').val();
    var route = $(this).parents('.fields').find('.reference_document_type').attr("data-request");
    $.ajax({
      url: "/"+route+"/fetch_ref_doc_item_number",
      type: 'GET',
      data: { number: number, type: ref_doc_type },
      dataType: 'json',
      success: function(data) {
        SelectId.empty();
        SelectId.append($('<option>').text('Select an option').attr('value', ''));
        $.each(data, function(key, value) {
          SelectId.append($('<option>').text(`${value.item_number}`).attr('value', value.item_number));
        });
      }
    });
  });
}

window.addEventListener('DOMContentLoaded', () => {
  Tags.init();

  let sideBar = localStorage.getItem("sideBar")
  if (sideBar === "true" || sideBar === true) {
    $("#wrapper").addClass("toggled");
  } else {
    $("#wrapper").removeClass("toggled");
  }

  setTimeout(function() {
    $('.flash_notice').slideUp()
  }, 2000);

  $('.generate-report-btn').on('click', function (e) {
    setTimeout(function() {
      $('form')[0].reset()
      $('.generate-report-btn').prop('disabled', false);
    }, 1000);
  });

  $("input[type='date']").on('click', function() {
    this.showPicker()
  })

  
  $("#menu-toggle").click(function(e) {
    e.preventDefault();
    $("#wrapper").toggleClass("toggled");
    let sideBar = localStorage.getItem("sideBar")

    if (sideBar === "false" || sideBar === false) {
      sideBar = true
    } else {
      sideBar = false
    }
    localStorage.setItem("sideBar", sideBar)
  });

  select2();
  select2_select();

  $("form").validate({
    invalidHandler: function(form, validator) {
      $(".form_submit_button").prop("disabled", false);
      $('.generate-report-btn').prop('disabled', false);
    }
  });

$(document).ready(function() {
  var table = $("Table").DataTable({
    "pageLength": 100
  });

  table.on('page', function() {
    window.scrollTo(0, 0);
  });

  $('form').submit(function(event) {
    var startDate = new Date($('#start_date').val());
    var endDate = new Date($('#end_date').val());
    if (startDate > endDate) {
      alert('The start date must be less than to the end date.');
      event.preventDefault();
    }
  });

});

  $(".dropdown-toggles").on("click", function() {
    $(".dropdown-menu").removeClass("show");
    $(this).siblings(".dropdown-menu").toggleClass("show");
  });

  $(document).on("click", function(event) {
    if (!$(event.target).closest(".dropdown").length) {
      $(".dropdown-menu").removeClass("show");
    }
  });

  $('.form-check-input').on("change", function(){
    $('.form-check-input')[0].checked == true ? $('#container').removeClass('hide') : $('#container').addClass('hide')
  });

  $('.epr-notification-check').on("change", function(){
    $('.epr-notification-check:checked').length > 0 ? $('#container-epr-notification').removeClass('hide') : $('#container-epr-notification').addClass('hide')
  });

  $('.import-email-check').on("change", function(){
    $('.import-email-check:checked').length > 0 ? $('#container-import-email').removeClass('hide') : $('#container-import-email').addClass('hide')
  });

  $('.org-email-check').on("change", function(event){
    event.target.checked ? $(`#org-email-check-${event.target.id}-container`).removeClass('hide') : $(`#org-email-check-${event.target.id}-container`).addClass('hide')
  });

  $('.org-epr-email-check').on("change", function(event){
    event.target.checked ? $(`#org-epr-email-check-${event.target.id}-container`).removeClass('hide') : $(`#org-epr-email-check-${event.target.id}-container`).addClass('hide')
  });

  $("#email-input").on("change", function() {
    var emailInput = $("#email-input").val();
    var emails = emailInput.split(",").map(function(email) {
      return email.trim();
    });

    var emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    var validEmails = [];
    var invalidEmails = [];

    for (var i = 0; i < emails.length; i++) {
      if (emailRegex.test(emails[i])) {
        validEmails.push(emails[i]);
      } else {
        invalidEmails.push(emails[i]);
      }
    }

    if (invalidEmails.length > 0) {
      var invalidEmailsStr = invalidEmails.join(", ");
      $("#user_notification_emails-error").text("Invalid email address(es): " + invalidEmailsStr).removeClass("hide");
    } else {
      $("#user_notification_emails-error").addClass("hide");
    }
  });

  $("#invoice_status").on("change", function() {
    if ($(this).val() === "Approved" || $(this).val() === "") {
      $(".submit_button").show();
      $(".rejection-modal-btn").hide();
      $(".paid-modal-btn").hide();
    } else if ($(this).val() === "Rejected") {
      $(".rejection-modal-btn").show();
      $(".submit_button").hide();
      $(".paid-modal-btn").hide();
    } else if ($(this).val() === "Paid") {
      $(".paid-modal-btn").show();
      $(".rejection-modal-btn").hide();
      $(".submit_button").hide();
    }
  });

  $(".form_submit_button").click(function() {
    $(this).prop("disabled", true);
    $("form").submit();
  });

  $(".role_permission_dropdown").change(function() {
    window.location.replace("/permissions?role_name=" + this.value);
  })

  $("#financing_rule_customer_dropdown").change(function() {
    window.location.replace("/financing_rules/new?customer_id=" + this.value);
  })

  $("#credit_limit_customer_dropdown").change(function() {
    window.location.replace("/credit_limits/new?customer_id=" + this.value);
  })

  $("#tijarah_customer_dropdown").change(function() {
    window.location.replace("/tijarah_models/new?customer_id=" + this.value);
  })

  $(".admin_role_permission_dropdown").change(function() {
    window.location.replace("/admin/permissions?role_name=" + this.value);
  })

  $(".select_customer").change(function() {
    window.location.replace("/settings?customer_id=" + this.value);
  })


  $(".selectall-checkbox-customer").change(function() {
    $(".checkbox-customer").prop('checked', $(this).is(":checked"))
  })

  $(".selectall-checkbox-supplier").change(function() {
    $(".checkbox-supplier").prop('checked', $(this).is(":checked"))
  })

  $(".selectall-checkbox-financier").change(function() {
    $(".checkbox-financier").prop('checked', $(this).is(":checked"))
  })

  $(".country").change(function() {
    $.ajax({
      url: "/states?country=" + this.value,
      type: "GET"
    })
  })

  $("#items_count").change(function() {
    if (document.location.href.includes('?')) {
      var url = new URL(window.location.href);
      var params = new URLSearchParams(url.search);
      params.delete('items');

      if (params == "") {
        var url = location.pathname + "?" + "items=" + this.value;
      } else {
        var url = location.pathname + "?" + params + "&items=" + this.value;
      }

    } else {
      var url = window.location.href + "?items=" + this.value;
    }
    window.location = url;
  });


  $(".otp").on('keyup', function(e) {
    if (e.keyCode == 8) { /* backspace */
      $(this).prev('.otp').focus();
    } else {
      $(this).next('.otp').focus();
    }
  });


  $("#discount_rule_default").click((event) => {
    if (event.target.checked == true) {
      $(".supplier_drop_down").hide();
    } else {
      $(".supplier_drop_down").show();
    }
  });

  $(document).ready(function() {
    if ($("#discount_rule_default").prop("checked") == true) {
      $(".supplier_drop_down").hide();
    } else {
      $(".supplier_drop_down").show();
    }
  });

  $("#financing_rule_default").click((event) => {
    if (event.target.checked == true) {
      $(".financier_drop_down").hide();
    } else {
      $(".financier_drop_down").show();
    }
  });

  $(document).ready(function() {
    if ($('.advance_select').length > 0) {
      $('.form-control.dropdown').remove()
      $('.advance_select').select2({
        placeholder: "Search & Select an Option",
        dropdownCssClass: "bg-light",
        selectionCssClass: "form-control form-select"
      });
    }
  });

  $("#ship").click(function() {
    var button = $(this).val();
    if (button == 1) {
      $(this).val(0);
      var name = $('#purchase_order_address_attributes_bill_to_party_name').val();
      $('#purchase_order_address_attributes_ship_to_party_name').val(name);
      var address_line_one = $('#purchase_order_address_attributes_billing_address_line_one').val();
      $('#purchase_order_address_attributes_shipping_address_line_one').val(address_line_one);
      var address_line_two = $('#purchase_order_address_attributes_billing_address_line_two').val();
      $('#purchase_order_address_attributes_shipping_address_line_two').val(address_line_two);
      var country = $('#address_country').val();
      $('#purchase_order_address_attributes_shipping_country').val(country);
      var state = $('#address_state').val();
      $('#purchase_order_address_attributes_shipping_state').val(state);
      var city = $('#address_city').val();
      $('#purchase_order_address_attributes_shipping_city').val(city);
      var post_code = $('#purchase_order_address_attributes_billing_post_code').val();
      $('#purchase_order_address_attributes_shipping_post_code').val(post_code);


    } else {

      $('#purchase_order_address_attributes_ship_to_party_name').val("");
      $('#purchase_order_address_attributes_shipping_address_line_one').val("");
      $('#purchase_order_address_attributes_shipping_address_line_two').val("");
      $('#purchase_order_address_attributes_shipping_country').val("");
      $('#purchase_order_address_attributes_shipping_state').val("");
      $('#purchase_order_address_attributes_shipping_city').val("");
      $('#purchase_order_address_attributes_shipping_post_code').val("");
      $(this).val(1);
    }

  });


  $("#invoice_shipping").click(function() {
    var button = $(this).val();
    if (button == 1) {
      $(this).val(0);
      var name = $('#invoice_address_attributes_bill_to_party_name').val();
      $('#invoice_address_attributes_ship_to_party_name').val(name);
      var address_line_one = $('#invoice_address_attributes_billing_address_line_one').val();
      $('#invoice_address_attributes_shipping_address_line_one').val(address_line_one);
      var address_line_two = $('#invoice_address_attributes_billing_address_line_two').val();
      $('#invoice_address_attributes_shipping_address_line_two').val(address_line_two);
      var country = $('#invoice_address_attributes_billing_country').find(":selected").text();
      $('#invoice_address_attributes_shipping_country').val(country);
      var state = $('#invoice_address_attributes_billing_state').find(":selected").text();
      $('#invoice_address_attributes_shipping_state').val(state);
      var city = $('#invoice_address_attributes_billing_city').find(":selected").text();
      $('#invoice_address_attributes_shipping_city').val(city);
      var post_code = $('#invoice_address_attributes_billing_post_code').val();
      $('#invoice_address_attributes_shipping_post_code').val(post_code);


    } else {

      $('#invoice_address_attributes_ship_to_party_name').val("");
      $('#invoice_address_attributes_shipping_address_line_one').val("");
      $('#invoice_address_attributes_shipping_address_line_two').val("");
      $('#invoice_address_attributes_shipping_country').val("");
      $('#invoice_address_attributes_shipping_state').val("");
      $('#invoice_address_attributes_shipping_city').val("");
      $('#invoice_address_attributes_shipping_post_code').val("");
      $(this).val(1);
    }

  });


  $("#financing_rule_customer_id").on("change", function() {
    var customer_id = $(this).val();
    $.ajax({
      url: "/financing_rules/show",
      method: "GET",
      dataType: "json",
      data: {
        customer_id: customer_id
      },
      error: function(xhr, status, error) {},
      success: function(response) {
        var customer_suppliers = response
        $("#financing_rule_supplier_id").empty();
        $("#financing_rule_supplier_id").append('<option>Select Supplier</option>');
        for (var i = 0; i < customer_suppliers.length; i++) {
          $("#financing_rule_supplier_id").append('<option value="' + customer_suppliers[i]["id"] + '">' + customer_suppliers[i]["name"] + '</option>');
        }
      }
    });
  });

  
  var current_user = getCookie('current_user_role');
  $.fn.dataTable.ext.errMode = 'none';

  $('#admin_purchase_order_table').DataTable({
    responsive: true,
    processing: true,
    searchDelay: 1000,
    "language": {
      searchPlaceholder: "Search Purchase Order"
    },
    "columnDefs": [{
      "targets": 0
    }, {
      "targets": 1
    }, {
      "targets": 2
    }, {
      "targets": 3
    }, {
      "targets": 4
    }, {
      "targets": 5
    }, {
      "targets": 6,
      "className": "hide"
    }, {
      "targets": 7,
      "orderable": false,
      "className": "text-center"
    }],
    "order": [[6, "desc"]],
    "buttons": [{
      "extend": 'csv',
      "text": '',
      "title": 'Fauree : Export data into CSV',
      "exportOptions": {
        "columns": ':visible'
      },
      "className": 'btn text-center cstm-btn bi bi-filetype-csv',
      action: async function (e, dt, button, config) {
        await createDownloadLogIfAllowed('admin/purchase_orders');
        $.fn.dataTable.ext.buttons.csvHtml5.action.call(this, e, dt, button, config);
      }
    }, {
      "extend": 'copy',
      "text": '',
      "className": 'btn text-center cstm-btn bi bi-clipboard'
    }],
    "dom": 'lfQtiBp'
  });

  $(document).on('click', '.dropdown-toggle1', function() {
    $('.dropdown-menu').removeClass('show');
    $(this).siblings('.dropdown-menu').toggleClass('show');
  });
  
  $(document).on('click', function(event) {
    if (!$(event.target).closest('.dropdown').length) {
      $('.dropdown-menu').removeClass('show');
    }
  });

  $('#admin_quantity_units_table').DataTable({
    responsive: true,
    processing: true,
    serverSide: true,
    searchDelay: 1000,
     "language": {
      searchPlaceholder: "Search Quantity Unit"
    },
    "ajax":$(location).attr('href'),

    "columnDefs": [{
      "targets": 0
    }, {
      "targets": 1
    }, {
      "targets": 2
    }, {
      "targets": 3
    }],
    "buttons": [{
      "extend": 'csv',
      "text": '',
      "title": 'Fauree : Export data into CSV',
      "exportOptions": {
        "columns": ':visible'
      },
      "className": 'btn text-center cstm-btn bi bi-filetype-csv',
      action: async function (e, dt, button, config) {
        await createDownloadLogIfAllowed('admin/quantity_units');
        $.fn.dataTable.ext.buttons.csvHtml5.action.call(this, e, dt, button, config);
      }
    }, {
      "extend": 'copy',
      "text": '',
      "className": 'btn text-center cstm-btn bi bi-clipboard'
    }],
    "dom": 'lfQtiBp'
  });

  $('#admin_organisations_table').DataTable({
    responsive: true,
    processing: true,
    serverSide: true,
    searchDelay: 1000,
     "language": {
      searchPlaceholder: "Search Organisation"
    },
    "ajax":$(location).attr('href'),
    "columnDefs": [{
      "targets": 0
    }, {
      "targets": 1
    }, {
      "targets": 2
    }, {
      "targets": 3
    }],
    "buttons": [{
      "extend": 'csv',
      "text": '',
      "title": 'Fauree : Export data into CSV',
      "exportOptions": {
        "columns": ':visible'
      },
      "className": 'btn text-center cstm-btn bi bi-filetype-csv'
    }, {
      "extend": 'copy',
      "text": '',
      "className": 'btn text-center cstm-btn bi bi-clipboard'
    }],
    "dom": 'lfQtiBp'
  });

  $('#admin_sites_table').DataTable({
    responsive: true,
    processing: true,
    serverSide: true,
    searchDelay: 1000,
     "language": {
      searchPlaceholder: "Search Site"
    },
    "ajax":$(location).attr('href'),
    "columnDefs": [{
      "targets": 0
    }, {
      "targets": 1
    }, {
      "targets": 2
    }, {
      "targets": 3
    }],
    "buttons": [{
      "extend": 'csv',
      "text": '',
      "title": 'Fauree : Export data into CSV',
      "exportOptions": {
        "columns": ':visible'
      },
      "className": 'btn text-center cstm-btn bi bi-filetype-csv'
    }, {
      "extend": 'copy',
      "text": '',
      "className": 'btn text-center cstm-btn bi bi-clipboard'
    }],
    "dom": 'lfQtiBp'
  });

  $('#admin_delivery_order_table').DataTable({
    responsive: true,
    processing: true,
    serverSide: true,
    searchDelay: 1000,
    "language": {
      searchPlaceholder: "Search Delivery Order"
    },
    "ajax": $(location).attr('href'),
    
    "columnDefs": [{
      "targets": 0
    }, {
      "targets": 1
    }, {
      "targets": 2
    }, {
      "targets": 3
    }, {
      "targets": 4
    }, {
      "targets": 5
    }, {
      "targets": 6
    }, {
      "targets": 7,
      "orderable": false,
      "className": "text-center"
    }],
    "buttons": [{
      "extend": 'csv',
      "text": '',
      "title": 'Fauree : Export data into CSV',
      "exportOptions": {
        "columns": ':visible'
      },
      "className": 'btn text-center cstm-btn bi bi-filetype-csv',
      action: async function (e, dt, button, config) {
        await createDownloadLogIfAllowed('admin/delivery_orders');
        $.fn.dataTable.ext.buttons.csvHtml5.action.call(this, e, dt, button, config);
      }
    }, {
      "extend": 'copy',
      "text": '',
      "className": 'btn text-center cstm-btn bi bi-clipboard'
    }],
    "dom": 'lfQtiBp',
  });


  $("#admin_customers_table").DataTable({
    responsive: true,
    processing: true,
    serverSide: true,
    searchDelay: 1000,
    ajax: $(location).attr("href"),
    language: {
      searchPlaceholder: "Search Customer",
    },

    columnDefs: [
      {
        targets: 0,
        width: "10%",
      },
      {
        targets: 1,
      },
      {
        targets: 2,
      },
      {
        targets: 3,
      },
      {
        targets: 4,
        orderable: false,
        className: "text-center",
      },
      {
        targets: 5,
        orderable: false,
        className: "text-center",
      },
    ],
    buttons: [
      {
        extend: "csv",
        text: "",
        title: "Fauree : Export data into CSV",
        exportOptions: {
          columns: ":visible",
        },
        className: "btn text-center cstm-btn bi bi-filetype-csv",
        action: async function (e, dt, button, config) {
          await createDownloadLogIfAllowed('admin/customers');
          $.fn.dataTable.ext.buttons.csvHtml5.action.call(this, e, dt, button, config);
        }
      },
      {
        extend: "copy",
        text: "",
        className: "btn text-center cstm-btn bi bi-clipboard",
      },
    ],
    dom: "lfQtiBp",
  });

  $('#admin_children_table').DataTable({

    "responsive": true,
    "processing": true,
    "language": {
      searchPlaceholder: "Search Sub User"
    },
    "ajax": {
      url: $(location).attr('href'),
      dataSrc: ""
    },

    "columns": [{
      "data": "id"
    }, {
      "data": "roles[0].name"
    }, {
      "data": "name"
    }, {
      "data": "email"
    }, {
      "data": "status",
      "render": function(data, type, row, meta) {
        if (row.status == 'Active') {
          return data;
        } else {
          return data;
        }
      }
    }, {
      "data": "id",
      'render': function(data) {
        return '<a href="' + $(location).attr('href') + "/" + data + '"><i class="bi bi-eye-fill" title="View"></i></a>';
      }
    }],


    "columnDefs": [{
      "targets": 0,
      "width": "10%"
    }, {
      "targets": 1
    }, {
      "targets": 2
    }, {
      "targets": 3
    }, {
      "targets": 4,
      "orderable": false,
      "className": "text-center"
    }, {
      "targets": 5,
      "orderable": false,
      "className": "text-center"
    }],
    "buttons": [{
      "extend": 'csv',
      "text": '',
      "title": 'Fauree : Export data into CSV',
      "exportOptions": {
        "columns": ':visible'
      },
      "className": 'btn text-center cstm-btn bi bi-filetype-csv'
    }, {
      "extend": 'copy',
      "text": '',
      "className": 'btn text-center cstm-btn bi bi-clipboard'
    }],
    "dom": 'lfQtiBp',
  });

  $("#sale_orders_table").DataTable({
    responsive: true,
    processing: true,
    serverSide: true,
    searchDelay: 1000,
    language: {
      searchPlaceholder: "Search Sale Order",
    },

    columnDefs: [
      {
        targets: 0,
      },
      {
        targets: 1,
      },
      {
        targets: 2,
      },
      {
        targets: 3,
      },
      {
        targets: 4,
      },
      {
        targets: 5,
      },
      {
        targets: 6,
        orderable: false,
      },
      {
        targets: 7,
        className: "hide",
      },
    ],
    order: [[7, "desc"]],
    buttons: [
      {
        extend: "csv",
        text: "",
        title: "Fauree : Export data into CSV",
        exportOptions: {
          columns: ":visible",
        },
        className: "btn text-center cstm-btn bi bi-filetype-csv",
      },
      {
        extend: "copy",
        text: "",
        className: "btn text-center cstm-btn bi bi-clipboard",
      },
    ],
    dom: "lfQtiBp",
  });

  $("#offer_to_sell_table").DataTable({
    responsive: true,
    processing: true,
    serverSide: true,
    searchDelay: 1000,
    language: {
      searchPlaceholder: "Search Offer To Sell",
    },

    columnDefs: [
      {
        targets: 0,
      },
      {
        targets: 1,
      },
      {
        targets: 2,
      },
      {
        targets: 3,
      },
      {
        targets: 4,
      },
      {
        targets: 5,
      },
      {
        targets: 6,
        orderable: false,
      },
      {
        targets: 7,
        className: "hide",
      },
    ],
    order: [[7, "desc"]],
    buttons: [
      {
        extend: "csv",
        text: "",
        title: "Fauree : Export data into CSV",
        exportOptions: {
          columns: ":visible",
        },
        className: "btn text-center cstm-btn bi bi-filetype-csv",
      },
      {
        extend: "copy",
        text: "",
        className: "btn text-center cstm-btn bi bi-clipboard",
      },
    ],
    dom: "lfQtiBp",
  });

  var supplierProgramTable = $('#supplier_program_table').DataTable({
    "responsive": true,
    "processing": true,
    "serverSide": true,
    stateSave: true, // Automatically save state in sessionStorage
    "language": {
      searchPlaceholder: "Search Program"
    },
    "ajax": $(location).attr('href'),
    "columnDefs": [{
      "targets": 0
    }, {
      "targets": 1
    }, {
      "targets": 2
    }, {
      "targets": 3
    }, {
      "targets": 4,
    },{
      "targets": 5
    }, {
      "targets": 6
    }, {
      "targets": 7
    }, {
      "targets": 8
    }, {
      "targets": 9,
      "className": "hide"
    }],
    "order": [[9, "desc"]],
    "buttons": [{
      "extend": 'csv',
      "text": '',
      "title": 'Fauree : Export data into CSV',
      "exportOptions": {
        "columns": ':visible'
      },
      "className": 'btn text-center cstm-btn bi bi-filetype-csv'
    }, {
      "extend": 'copy',
      "text": '',
      "className": 'btn text-center cstm-btn bi bi-clipboard'
    }],
    "dom": 'lfQtiBp',
  });
  var urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has('search')) {
      // Apply search parameter
      supplierProgramTable.search(urlParams.get('search')).draw();
  }
  if (urlParams.has('order')) {
      // Apply order parameter
      var orderParams = urlParams.get('order').split(',');
      supplierProgramTable.order(orderParams[0], orderParams[1]).draw();
  }

  // Save state to URL when state changes
  supplierProgramTable.on('stateSaveParams.dt', function(e, settings, data) {
      var search = supplierProgramTable.search();
      var queryParams = [];
      if (search) {
          queryParams.push('search=' + search);
      }
      var newUrl = window.location.pathname + '?' + queryParams.join('&');
      window.history.replaceState({}, '', newUrl);
  });


  $("#supplier-locations-table").DataTable({
    responsive: true,
    processing: true,
    serverSide: true,
    searchDelay: 1000,
    language: {
      searchPlaceholder: "Search Supplier Locations",
    },
    columnDefs: [
      {
        targets: 0,
      },
      {
        targets: 1,
      },
      {
        targets: 2,
      },
      {
        targets: 3,
      },
      {
        targets: 4,
      },
      {
        targets: 5,
        className: "hide",
      },
    ],
    order: [[5, "desc"]],
    buttons: [
      {
        extend: "csv",
        text: "",
        title: "Fauree : Export data into CSV",
        exportOptions: {
          columns: ":visible",
        },
        className: "btn text-center cstm-btn bi bi-filetype-csv",
      },
      {
        extend: "copy",
        text: "",
        className: "btn text-center cstm-btn bi bi-clipboard",
      },
    ],
    dom: "lfQtiBp",
  });


  $('#admin_suppliers_table').DataTable({

    responsive: true,
    processing: true,
    serverSide: true,
    searchDelay: 1000,
    "language": {
      searchPlaceholder: "Search Supplier"
    },
    "ajax": $(location).attr('href'),

    "columnDefs": [{
      "targets": 0,
      "width": "10%"
    }, {
      "targets": 1
    }, {
      "targets": 2
    }, {
      "targets": 3
    }, {
      "targets": 4,
      "orderable": false,
      "className": "text-center"
    }, {
      "targets": 5,
      "orderable": false,
      "className": "text-center"
    }],
    "buttons": [{
      "extend": 'csv',
      "text": '',
      "title": 'Fauree : Export data into CSV',
      "exportOptions": {
        "columns": ':visible'
      },
      "className": 'btn text-center cstm-btn bi bi-filetype-csv',
      action: async function (e, dt, button, config) {
        await createDownloadLogIfAllowed('admin/suppliers');
        $.fn.dataTable.ext.buttons.csvHtml5.action.call(this, e, dt, button, config);
      }
    }, {
      "extend": 'copy',
      "text": '',
      "className": 'btn text-center cstm-btn bi bi-clipboard'
    }],
    "dom": 'lfQtiBp',
  });

  function getCookie(name) {
    function escape(s) {
      return s.replace(/([.*+?\^$(){}|\[\]\/\\])/g, '\\$1');
    }
    var match = document.cookie.match(RegExp('(?:^|;\\s*)' + escape(name) + '=([^;]*)'));
    return match ? match[1] : null;
  }

  $('#admin_financier_table').DataTable({
    responsive: true,
    processing: true,
    serverSide: true,
    searchDelay: 1000,
    ajax: $(location).attr("href"),
    "language": {
      searchPlaceholder: "Search Financier"
    },

    "columnDefs": [{
      "targets": 0,
      "width": "10%"
    }, {
      "targets": 1
    }, {
      "targets": 2
    }, {
      "targets": 3
    }, {
      "targets": 4,
      "orderable": false,
      "className": "text-center"
    }, {
      "targets": 5,
      "orderable": false,
      "className": "text-center"
    }],
    "buttons": [{
      "extend": 'csv',
      "text": '',
      "title": 'Fauree : Export data into CSV',
      "exportOptions": {
        "columns": ':visible'
      },
      "className": 'btn text-center cstm-btn bi bi-filetype-csv',
      action: async function (e, dt, button, config) {
        await createDownloadLogIfAllowed('admin/financiers');
        $.fn.dataTable.ext.buttons.csvHtml5.action.call(this, e, dt, button, config);
      }
    }, {
      "extend": 'copy',
      "text": '',
      "className": 'btn text-center cstm-btn bi bi-clipboard'
    }],
    "dom": 'lfQtiBp',
  });



  $('#admin_invoice_table').DataTable({
    responsive: true,
    processing: true,
    serverSide: true,
    searchDelay: 1000,
    "language": {
      searchPlaceholder: "Search Invoice"
    },

    "ajax": $(location).attr('href'),

    "columnDefs": [{
      "targets": 0
    }, {
      "targets": 1
    }, {
      "targets": 2
    }, {
      "targets": 3
    }, {
      "targets": 4
    }, {
      "targets": 5
    }, {
      "targets": 6
    }, {
      "targets": 7
    }, {
      "targets": 8
    }, {
      "targets": 9
    }, {
      "targets": 10,
      "orderable": false,
      "className": "text-center"
    }, {
      "targets": 11,
      "className": "hide"
    }],
    "order": [[11, "desc"]],
    "buttons": [{
      "extend": 'csv',
      "text": '',
      "title": 'Fauree : Export data into CSV',
      "exportOptions": {
        "columns": ':visible'
      },
      "className": 'btn text-center cstm-btn bi bi-filetype-csv',
      action: async function (e, dt, button, config) {
        await createDownloadLogIfAllowed('admin/invoices');
        $.fn.dataTable.ext.buttons.csvHtml5.action.call(this, e, dt, button, config);
      }
    }, {
      "extend": 'copy',
      "text": '',
      "className": 'btn text-center cstm-btn bi bi-clipboard'
    }],
    "dom": 'lfQtiBp',
  });

  $('#admin_early_payment_requests_table').DataTable({

    responsive: true,
    processing: true,
    serverSide: true,
    searchDelay: 1000,
    "language": {
      searchPlaceholder: "Search EPR"
    },

    "ajax": $(location).attr('href'),

    "columnDefs": [{
      "targets": 0
    }, {
      "targets": 1
    }, {
      "targets": 2
    }, {
      "targets": 3
    }, {
      "targets": 4
    }, {
      "targets": 5
    }, {
      "targets": 6
    }, {
      "targets": 7
    }, {
      "targets": 8
    }, {
      "targets": 9
    }, {
      "targets": 10,
      "orderable": false,
      "className": "text-center"
    }, {
      "targets": 11,
      "className": "hide"
    }],
    "order": [[11, "desc"]],
    "buttons": [{
      "extend": 'csv',
      "text": '',
      "title": 'Fauree : Export data into CSV',
      "exportOptions": {
        "columns": ':visible'
      },
      "className": 'btn text-center cstm-btn bi bi-filetype-csv',
      action: async function (e, dt, button, config) {
        await createDownloadLogIfAllowed('admin/early_payment_requests');
        $.fn.dataTable.ext.buttons.csvHtml5.action.call(this, e, dt, button, config);
      }
    }, {
      "extend": 'copy',
      "text": '',
      "className": 'btn text-center cstm-btn bi bi-clipboard'
    }],
    "dom": 'lfQtiBp',
  });

  var writtenOffersTable = $('#written_offers_table').DataTable({
    responsive: true,
    processing: true,
    serverSide: true,
    stateSave: true,
    "language": {
      "searchPlaceholder": "Search Written Offer"
    },
    "ajax": $(location).attr('href'),
    "columnDefs": [
      { "targets": 0 }, // ID
      { "targets": 1 }, // Date
      { "targets": 2 }, // PO #
      { "targets": 3 }, // TTN
      { "targets": 4 }, // Supplier/Customer
      { "targets": 5 }, // Amount
      { "targets": 6 }, // Status
      { "targets": 7 }, // Action
      { "targets": 8 } // Updated At
    ],
    "order": [[8, "desc"]], // Order by the "Updated At" column
    "buttons": [
      {
        "extend": 'csv',
        "text": '',
        "title": 'Fauree : Export data into CSV',
        "exportOptions": {
          "columns": ':visible'
        },
        "className": 'btn text-center cstm-btn bi bi-filetype-csv'
      },
      {
        "extend": 'copy',
        "text": '',
        "className": 'btn text-center cstm-btn bi bi-clipboard'
      }
    ],
    "dom": 'lfQtiBp'
  });

  // URL parameter handling
  var urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has('search')) {
    writtenOffersTable.search(urlParams.get('search')).draw();
  }

  if (urlParams.has('order')) {
    var orderParams = urlParams.get('order').split(',');
    writtenOffersTable.order(orderParams[0], orderParams[1]).draw();
  }

  // Save state to URL when state changes
  writtenOffersTable.on('stateSaveParams.dt', function(e, settings, data) {
    var search = writtenOffersTable.search();
    var queryParams = [];
    if (search) {
      queryParams.push('search=' + search);
    }
    var newUrl = window.location.pathname + '?' + queryParams.join('&');
    window.history.replaceState({}, '', newUrl);
  });

  var deliveryOrderTable = $('#delivery_orders_table').DataTable({
    "responsive": true,
    "processing": true,
    serverSide: true,
    stateSave: true,
    "language": {
      searchPlaceholder: "Search"
    },
    "ajax": $(location).attr('href'),
    "columnDefs": [{
      "targets": 0
    }, {
      "targets": 1
    }, {
      "targets": 2
    }, {
      "targets": 3
    }, {
      "targets": 4
    },{
      "targets": 5
    }, {
      "targets": 6
    }, {
      "targets": 7
    }, {
      "targets": 8, 
      "className": "hide" 
    }],
    "order": [[8, "desc"]],
    "buttons": [{
      "extend": 'csv',
      "text": '',
      "title": 'Fauree : Export data into CSV',
      "exportOptions": {
        "columns": ':visible'
      },
      "className": 'btn text-center cstm-btn bi bi-filetype-csv'
    }, {
      "extend": 'copy',
      "text": '',
      "className": 'btn text-center cstm-btn bi bi-clipboard'
    }],
    "dom": 'lfQtiBp',
  });
  var urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has('search')) {
      // Apply search parameter
      deliveryOrderTable.search(urlParams.get('search')).draw();
  }

  // Save state to URL when state changes
  deliveryOrderTable.on('stateSaveParams.dt', function(e, settings, data) {
      var search = deliveryOrderTable.search();
      var queryParams = [];
      if (search) {
          queryParams.push('search=' + search);
      }
      var newUrl = window.location.pathname + '?' + queryParams.join('&');
      window.history.replaceState({}, '', newUrl);
  });


  var invTable = $('#invoices_table').DataTable({
    responsive: true,                
    processing: true,                
    serverSide: true,                
    searchDelay: 1000,               
    stateSave: true,                 
    pageLength: 10,                  
    language: {
      searchPlaceholder: "Search Invoice",
    },
    ajax: $(location).attr('href'),
    columnDefs: [{
      targets: 0
    }, {
      targets: 1
    }, {
      targets: 2
    }, {
      targets: 3
    }, {
      targets: 4
    }, {
      targets: 5
    }, {
      targets: 6
    }, {
      targets: 7
    }, {
      targets: 8
    }, {
      targets: 9
    }, {
      targets: 10
    }, {
      targets: 11,
      orderable: false,
      className: "text-center"
    }, {
      targets: 12,
      className: "hide"
    }],
    order: [[12, "desc"]],
    buttons: [{
      extend: 'csv',
      text: '',
      title: 'Fauree : Export data into CSV',
      exportOptions: {
        columns: ':visible'
      },
      className: 'btn text-center cstm-btn bi bi-filetype-csv',
      action: async function (e, dt, button, config) {
        await createDownloadLogIfAllowed('invoices');
        $.fn.dataTable.ext.buttons.csvHtml5.action.call(this, e, dt, button, config);
      }
    }, {
      extend: 'copy',
      text: '',
      className: 'btn text-center cstm-btn bi bi-clipboard'
    }],
    dom: 'lfQtiBp'
  });

  // Add URL parameter handling for search state
  var urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has('search')) {
    invTable.search(urlParams.get('search')).draw();
  }

  // Save state to URL when state changes
  invTable.on('stateSaveParams.dt', function(e, settings, data) {
    var search = invTable.search();
    var queryParams = [];
    if (search) {
      queryParams.push('search=' + search);
    }
    var newUrl = window.location.pathname + '?' + queryParams.join('&');
    window.history.replaceState({}, '', newUrl);
  });


  const financierInvoicesTable = $('#financier_invoices_table').DataTable({
    responsive: true,
    processing: true,
    serverSide: true,
    searchDelay: 1000,
    stateSave: true, // Automatically save state in sessionStorage
    "language": {
      searchPlaceholder: "Search Invoice"
    },
    "ajax":$(location).attr('href'),

    "columnDefs": [{
      "orderable": false,
      "targets": 0
    }, {
      "targets": 1
    }, {
      "targets": 2
    }, {
      "targets": 3
    }, {
      "targets": 4
    }, {
      "targets": 5
    }, {
      "targets": 6
    }, {
      "targets": 7
    }, {
      "targets": 8
    }, {
      "targets": 9
    }, {
      "targets": 10
    }, {
      "targets": 11
    }, {
      "targets": 12
    }, {
      "targets": 13,
      "orderable": false,
      "className": "text-center"
    },{
      "targets": 14,
      "className": "hide"
    }],
    "order": [[14, "desc"]],
    "buttons": [{
      "extend": 'csv',
      "text": '',
      "title": 'Fauree : Export data into CSV',
      "exportOptions": {
        "columns": ':visible'
      },
      "className": 'btn text-center cstm-btn bi bi-filetype-csv'
    }, {
      "extend": 'copy',
      "text": '',
      "className": 'btn text-center cstm-btn bi bi-clipboard'
    }],
    "dom": 'lfQtiBp',
  });

  // Check for URL parameters and apply them to DataTable
  var urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has('search')) {
      // Apply search parameter
      financierInvoicesTable.search(urlParams.get('search')).draw();
  }
  if (urlParams.has('order')) {
      // Apply order parameter
      var orderParams = urlParams.get('order').split(',');
      financierInvoicesTable.order(orderParams[0], orderParams[1]).draw();
  }

  // Save state to URL when state changes
  financierInvoicesTable.on('stateSaveParams.dt', function(e, settings, data) {
      var search = financierInvoicesTable.search();
      var queryParams = [];
      if (search) {
          queryParams.push('search=' + search);
      }
      var newUrl = window.location.pathname + '?' + queryParams.join('&');
      window.history.replaceState({}, '', newUrl);
  });

  const detailRows = [];
  function epr_table_inside_invoices(data) {
    var eprArray = data.filter(item => typeof item === 'object' && item)

    if (eprArray.length == 0) {
      return 'No Approved/Paid EPR'
    }

    var html = '<table class="child-table">';
    html += `<thead>
              <tr>
                <th class="text-center">EPR ID</th>
                <th class="text-center">Submit Date</th>
                <th class="text-center">EPR Charges</th>
                <th class="text-center">EPR Amount</th>
                <th class="text-center">Status</th>
                <th class="text-center">Action</th>
              </tr>
            </thead>`;
    html += '<tbody>';

    for (const i in eprArray) {
      html += `<tr>
                <td>${eprArray[i].epr.id}</td>
                <td>${eprArray[i].epr.submit_date}</td>
                <td>${eprArray[i].epr.epr_charges}</td>
                <td>${eprArray[i].epr.epr_amount}</td>
                <td>${eprArray[i].epr.status}</td>
                <td>${eprArray[i].epr.link}</td>
              </tr>`;
    }
    
    html += '</tbody>';
    html += '</table>';
    
    return html;
  }

  financierInvoicesTable.on('click', 'tbody i.dt-control', function () {
    let tr = event.target.closest('tr');
    let row = financierInvoicesTable.row(tr);
    let idx = detailRows.indexOf(tr.id);
 
    if (row.child.isShown()) {
        tr.classList.remove('details');
        event.target.classList.remove('bi-arrow-up-circle');
        event.target.classList.add('bi-arrow-down-circle');
        row.child.hide();
        detailRows.splice(idx, 1);
    }
    else {
        tr.classList.add('details');
        event.target.classList.remove('bi-arrow-down-circle');
        event.target.classList.add('bi-arrow-up-circle');
        row.child(epr_table_inside_invoices(row.data())).show();
        row.child()[0].classList.add('bg-light')
        if (idx === -1) {
            detailRows.push(tr.id);
        }
    }
  });

  financierInvoicesTable.on('draw', () => {
    detailRows.forEach((id, i) => {
      let el = document.querySelector('#' + id + ' i.dt-control');
      if (el) {
        el.dispatchEvent(new Event('click', { bubbles: true }));
      }
    });
  });

  $('#financier_invoices_table').DataTable({
    responsive: true,
    processing: true,
    serverSide: true,
    searchDelay: 1000,
    "language": {
        searchPlaceholder: "Search Invoice"
    },
    "columnDefs": [
        // your column definitions here
    ],
    "order": [[14, "desc"]],
    "buttons": [
        // your button definitions here
    ],
    "dom": 'lfQtiBp',
    initComplete: function () {
        var table = this;
        table.api().columns().every(function () {
            var column = this;
            if (column.index() === 0 || column.index() === 1) {
                var select = $('<select><option value=""></option></select>')
                    .appendTo($(column.footer()).empty())
                    .on('change', function () {
                        var val = $.fn.dataTable.util.escapeRegex($(this).val());
                        column.search(val ? '^' + val + '$' : '', true, false).draw();
                    });

                column.data().unique().sort().each(function (d, j) {
                    select.append('<option value="' + d + '">' + d + '</option>');
                });
            }
          });
      }
  });

  var suppliersTable = $('#suppliers_table').DataTable({
    "responsive": true,
    "processing": true,
    serverSide: true,
    stateSave: true, // Automatically save state in sessionStorage
    "language": {
      searchPlaceholder: "Search Suppliers"
    },
    "ajax": $(location).attr('href'),
    "columnDefs": [{
      "targets": 0,
      "orderable": false
    }, {
      "targets": 1
    }, {
      "targets": 2
    }, {
      "targets": 3
    }, {
      "targets": 4,
    },{
      "targets": 5
    },{
      "targets": 6
    },{
      "targets": 7
    },{
      "targets": 8
    },{
      "targets": 9,
      "className": "hide"
    }],
    "order": [[9, "desc"]],
    "buttons": [{
      "extend": 'csv',
      "text": '',
      "title": 'Fauree : Export data into CSV',
      "exportOptions": {
        "columns": ':visible'
      },
      "className": 'btn text-center cstm-btn bi bi-filetype-csv'
    }, {
      "extend": 'copy',
      "text": '',
      "className": 'btn text-center cstm-btn bi bi-clipboard'
    }],
    "dom": 'lfQtiBp',
  });
  var urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has('search')) {
      // Apply search parameter
      suppliersTable.search(urlParams.get('search')).draw();
  }
  if (urlParams.has('order')) {
      // Apply order parameter
      var orderParams = urlParams.get('order').split(',');
      suppliersTable.order(orderParams[0], orderParams[1]).draw();
  }

  // Save state to URL when state changes
  suppliersTable.on('stateSaveParams.dt', function(e, settings, data) {
      var search = suppliersTable.search();
      var queryParams = [];
      if (search) {
          queryParams.push('search=' + search);
      }
      var newUrl = window.location.pathname + '?' + queryParams.join('&');
      window.history.replaceState({}, '', newUrl);
  });

  $('#versions_table').DataTable({
    responsive: true,
    processing: true,
    "language": {
      searchPlaceholder: "Search"
    },
    "columnDefs": [
      { "orderable": false, "className": "text-center", "targets": [0] },
      { "targets": [1] },
      { "targets": [2] },
      { "targets": [3] },
      { "targets": [4] },
      { "targets": [5] },
      { "targets": [6] }
    ],
    "buttons": [
      {
        "extend": 'csv',
        "text": '',
        "title": 'Fauree : Export data into CSV',
        "exportOptions": {
          "columns": ':visible'
        },
        "className": 'btn text-center cstm-btn bi bi-filetype-csv'
      },
      {
        "extend": 'copy',
        "text": '',
        "className": 'btn text-center cstm-btn bi bi-clipboard'
      }
    ],
    "dom": 'lfQtiBp',
    "initComplete": function(settings, json) {
      if ($('#versions_table tbody tr').length == 0) {
        $('#versions_table tbody').html('<tr><td colspan="7" class="text-center">No records found</td></tr>');
      }
    }
  });

  $('#inbound_request_logs_table').DataTable({

    responsive: true,
    processing: true,
    serverSide: true,
    "language": {
      searchPlaceholder: "Search Log"
    },
    "ajax": $(location).attr('href'),
    "columnDefs": [{
      "targets": 0,
      "width": "5%",
    },{
      "targets": 1,
      "width": "5%",
    },{
      "targets": 2,
      "width": "5%",
    },{
      "targets": 3,
      "width": "5%",
    },{
      "targets": 4,
      "className": 'table-data',
      "width": "35%",
      "render": function(data, type, row, meta) {
          return '<div class="text-container">' + data + '</div>';
      }
    },{
      "targets": 5,
      "className": 'table-data',
      "width": "35%",
      "render": function(data, type, row, meta) {
          return '<div class="text-container">' + data + '</div>';
      }
    },{
      "targets": 6,
      "width": "10%"
    }],
    "order": [[1, "desc"]],
    "buttons": [{
      "extend": 'csv',
      "text": 'Export',
      "title": 'Fauree : Export data into CSV',
      "exportOptions": {
        "columns": ':visible'
      },
      "className": 'btn text-center cstm-btn bi bi-filetype-csv'
    }, {
      "extend": 'copy',
      "text": 'Copy',
      "title": 'Fauree : Copy data on Clipboard',
      "className": 'btn text-center cstm-btn bi bi-clipboard'
    }],
    "dom": 'lfQtiBp',
    "createdRow": function(row, data, dataIndex) {
      // Add "show more" functionality
      $(row).find('.text-container').each(function() {
          var fullText = $(this).text();
          var truncatedText = fullText.substring(0, 100);
          $(this).data('fullText', fullText);
          $(this).data('truncated', true);
          $(this).html(truncatedText + '<span class="toggle-more">... <a href="#" class="show-more">Show more</a></span>');
      });
    }
  });

  // Toggle "show more" and "show less"
  $(document).on('click', '.show-more, .show-less', function(e) {
    e.preventDefault();
    var textContainer = $(this).closest('.text-container');
    var fullText = textContainer.data('fullText');
    
    if (textContainer.data('truncated')) {
        textContainer.html(fullText + ' <span class="toggle-more"><a href="#" class="show-less">Show less</a></span>');
        textContainer.data('truncated', false);
    } else {
        var truncatedText = fullText.substring(0, 100);
        textContainer.html(truncatedText + '... <span class="toggle-more"><a href="#" class="show-more">Show more</a></span>');
        textContainer.data('truncated', true);
    }
  });

  $('#notifications_table').DataTable({
    responsive: true,
    processing: true,
    "language": {
      searchPlaceholder: "Search Notifications"
    },
    "columnDefs": [
      { "orderable": false, "className": "text-center", "targets": [0] },
      { "targets": [1] },
      { "targets": [2] },
      { "targets": [3] },
      { "targets": [4] },
      { "targets": [5] },
      { "orderable": false, "targets": [6] }
    ],
    "buttons": [
      {
        "extend": 'csv',
        "text": '',
        "title": 'Fauree : Export data into CSV',
        "exportOptions": {
          "columns": ':visible'
        },
        "className": 'btn text-center cstm-btn bi bi-filetype-csv'
      },
      {
        "extend": 'copy',
        "text": '',
        "className": 'btn text-center cstm-btn bi bi-clipboard'
      }
    ],
    "dom": 'lfQtiBp',
    "initComplete": function(settings, json) {
      if ($('#notifications_table tbody tr').length == 0) {
        $('#notifications_table tbody').html('<tr><td colspan="7" class="text-center">No records found</td></tr>');
      }
    }
  });

  $('#outbound_request_logs_table').DataTable({
    responsive: true,
    processing: true,
    serverSide: true,
    searchDelay: 1000,
    "language": {
      searchPlaceholder: "Search Logs"
    },
    "ajax": $(location).attr('href'),

    "columnDefs": [{
      "targets": 0,
      "width": "5%",
    }, {
      "targets": 1,
      "width": "5%",
    }, {
      "targets": 2,
      "width": "5%",
    }, {
      "targets": 3,
      "width": "5%",
    },{
      "targets": 4,
      "className": 'table-data',
      "width": "35%",
      "render": function(data, type, row, meta) {
          return '<div class="text-container">' + data + '</div>';
      }
    },{
      "targets": 5,
      "className": 'table-data',
      "width": "35%",
      "render": function(data, type, row, meta) {
          return '<div class="text-container">' + data + '</div>';
      }
    },{
      "targets": 6,
      "width": "10%"
    }],
    "order": [[1, "desc"]],
    "buttons": [{
      "extend": 'csv',
      "text": 'Export',
      "title": 'Fauree : Export data into CSV',
      "exportOptions": {
        "columns": ':visible'
      },
      "className": 'btn text-center cstm-btn bi bi-filetype-csv'
    }, {
      "extend": 'copy',
      "text": 'Copy',
      "title": 'Fauree : Copy data on Clipboard',
      "className": 'btn text-center cstm-btn bi bi-clipboard'
    }],
    "dom": 'lfQtiBp',
    "createdRow": function(row, data, dataIndex) {
      // Add "show more" functionality
      $(row).find('.text-container').each(function() {
          var fullText = $(this).text();
          var truncatedText = fullText.substring(0, 100);
          $(this).data('fullText', fullText);
          $(this).data('truncated', true);
          $(this).html(truncatedText + '<span class="toggle-more">... <a href="#" class="show-more-link">Show more</a></span>');
      });
    }
  });

  // Toggle "show more" and "show less"
  $(document).on('click', '.show-more-link, .show-less-link', function(e) {
    e.preventDefault();
    var textContainer = $(this).closest('.text-container');
    var fullText = textContainer.data('fullText');
    
    if (textContainer.data('truncated')) {
        textContainer.html(fullText + ' <span class="toggle-more"><a href="#" class="show-less-link">Show less</a></span>');
        textContainer.data('truncated', false);
    } else {
        var truncatedText = fullText.substring(0, 100);
        textContainer.html(truncatedText + '... <span class="toggle-more"><a href="#" class="show-more-link">Show more</a></span>');
        textContainer.data('truncated', true);
    }
  });

  $('#versions_table').DataTable({
    responsive: true,
    processing: true,
    "language": {
      searchPlaceholder: "Search"
    },
    "columnDefs": [
      { "orderable": false, "className": "text-center", "targets": [0] },
      { "targets": [1] },
      { "targets": [2] },
      { "targets": [3] },
      { "targets": [4] },
      { "targets": [5] },
      { "targets": [6] }
    ],
    "buttons": [
      {
        "extend": 'csv',
        "text": '',
        "title": 'Fauree : Export data into CSV',
        "exportOptions": {
          "columns": ':visible'
        },
        "className": 'btn text-center cstm-btn bi bi-filetype-csv'
      },
      {
        "extend": 'copy',
        "text": '',
        "className": 'btn text-center cstm-btn bi bi-clipboard'
      }
    ],
    "dom": 'lfQtiBp',
    "initComplete": function(settings, json) {
      if ($('#versions_table tbody tr').length == 0) {
        $('#versions_table tbody').html('<tr><td colspan="7" class="text-center">No records found</td></tr>');
      }
    }
  });

  $('#versions_download_log_table').DataTable({
    responsive: true,
    processing: true,
    "language": {
      searchPlaceholder: "Search"
    },
    "columnDefs": [
      { "orderable": false, "className": "text-center", "targets": [0] },
      { "targets": [1] },
      { "targets": [2] },
      { "targets": [3] },
      { "targets": [4] },
      { "targets": [5] },
      { "targets": [6] },
      { "targets": [7] },
      {
        "targets": 8,
        "className": 'table-data',
        "width": "25%",
        "render": function(data, type, row, meta) {
          if (type === 'display' && data.length > 40) { // Adjust 50 characters as needed
            return '<div class="text-container">' + data.substring(0, 40) + '... <a href="#" class="show-more">Show More</a></div>';
          } else {
            return '<div class="text-container">' + data + '</div>';
          }
        }
      }
    ],
    "buttons": [
      {
        "extend": 'csv',
        "text": '',
        "title": 'Fauree : Export data into CSV',
        "exportOptions": {
          "columns": ':visible'
        },
        "className": 'btn text-center cstm-btn bi bi-filetype-csv'
      },
      {
        "extend": 'copy',
        "text": '',
        "className": 'btn text-center cstm-btn bi bi-clipboard'
      }
    ],
    "dom": 'lfQtiBp',
    "initComplete": function(settings, json) {
      if ($('#versions_download_log_table tbody tr').length == 0) {
        $('#versions_download_log_table tbody').html('<tr><td colspan="7" class="text-center">No records found</td></tr>');
      }
    }
  });

  $('#versions_download_log_table').DataTable({
    responsive: true,
    processing: true,
    "language": {
      searchPlaceholder: "Search"
    },
    "columnDefs": [
      { "orderable": false, "className": "text-center", "targets": [0] },
      { "targets": [1] },
      { "targets": [2] },
      { "targets": [3] },
      { "targets": [4] },
      { "targets": [5] },
      {
        "targets": 8,
        "className": 'table-data',
        "width": "25%",
        "render": function(data, type, row, meta) {
          if (type === 'display' && data.length > 40) { // Adjust 50 characters as needed
            return '<div class="text-container">' + data.substring(0, 40) + '... <a href="#" class="show-more">Show More</a></div>';
          } else {
            return '<div class="text-container">' + data + '</div>';
          }
        }
      }
    ],
    "buttons": [
      {
        "extend": 'csv',
        "text": '',
        "title": 'Fauree : Export data into CSV',
        "exportOptions": {
          "columns": ':visible'
        },
        "className": 'btn text-center cstm-btn bi bi-filetype-csv'
      },
      {
        "extend": 'copy',
        "text": '',
        "className": 'btn text-center cstm-btn bi bi-clipboard'
      }
    ],
    "dom": 'lfQtiBp'
  });

// Handle Show More functionality
  $('#versions_download_log_table tbody').on('click', '.show-more', function(e) {
    e.preventDefault();
    var cell = $(this).closest('td');
    var rowData = $('#versions_download_log_table').DataTable().row(cell.closest('tr')).data();
    cell.html('<div class="text-container">' + rowData[8] + '</div>');
  });

  var kiborRateTable = $('#kibor_rate_id').DataTable({
    "responsive": true,
    "processing": true,
    serverSide: true,
    stateSave: true, // Automatically save state in sessionStorage
    "language": {
      searchPlaceholder: "Search Kibor Rate"
    },
    "ajax": $(location).attr('href'),
    "columnDefs": [{
      "targets": 0
    }, {
      "targets": 1
    }, {
      "targets": 2
    }, {
      "targets": 3
    }, {
      "targets": 4,
    }, {
      "targets": 5,
    }, {
      "targets": 6,
    }, {
      "targets": 7,
    }, {
      "targets": 8,
    }, {
      "targets": 9,
    }, {
      "targets": 10, "className": "hide"
    }],
    "order": [[10, "desc"]],
    "buttons": [{
      "extend": 'csv',
      "text": '',
      "title": 'Fauree : Export data into CSV',
      "exportOptions": {
        "columns": ':visible'
      },
      "className": 'btn text-center cstm-btn bi bi-filetype-csv'
    }, {
      "extend": 'copy',
      "text": '',
      "className": 'btn text-center cstm-btn bi bi-clipboard'
    }],
    "dom": 'lfQtiBp',
  });
  var urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has('search')) {
      // Apply search parameter
      kiborRateTable.search(urlParams.get('search')).draw();
  }
  if (urlParams.has('order')) {
      // Apply order parameter
      var orderParams = urlParams.get('order').split(',');
      kiborRateTable.order(orderParams[0], orderParams[1]).draw();
  }

  // Save state to URL when state changes
  kiborRateTable.on('stateSaveParams.dt', function(e, settings, data) {
      var search = kiborRateTable.search();
      var queryParams = [];
      if (search) {
          queryParams.push('search=' + search);
      }
      var newUrl = window.location.pathname + '?' + queryParams.join('&');
      window.history.replaceState({}, '', newUrl);
  });

  var customersTable = $('#customers_table').DataTable({
    "responsive": true,
    "processing": true,
    serverSide: true,
    stateSave: true, // Automatically save state in sessionStorage
    "language": {
      searchPlaceholder: "Search Anchor"
    },
    "ajax": $(location).attr('href'),
    "columnDefs": [{
      "targets": 0
    }, {
      "targets": 1
    }, {
      "targets": 2
    }, {
      "targets": 3
    }, {
      "targets": 4,
      "orderable": false,
      "className": "text-center"
    },{
      "targets": 5
    },{
      "targets": 6
    },{
      "targets": 7, "className": "hide"
    }],
    "order": [[7, "desc"]],
    "buttons": [{
      "extend": 'csv',
      "text": '',
      "title": 'Fauree : Export data into CSV',
      "exportOptions": {
        "columns": ':visible'
      },
      "className": 'btn text-center cstm-btn bi bi-filetype-csv'
    },  
    {
      "extend": 'copy',
      "text": '',
      "className": 'btn text-center cstm-btn bi bi-clipboard'
    }],
    "dom": 'lfQtiBp',
  });
  var urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has('search')) {
      // Apply search parameter
      customersTable.search(urlParams.get('search')).draw();
  }
  if (urlParams.has('order')) {
      // Apply order parameter
      var orderParams = urlParams.get('order').split(',');
      customersTable.order(orderParams[0], orderParams[1]).draw();
  }

  // Save state to URL when state changes
  customersTable.on('stateSaveParams.dt', function(e, settings, data) {
      var search = customersTable.search();
      var queryParams = [];
      if (search) {
          queryParams.push('search=' + search);
      }
      var newUrl = window.location.pathname + '?' + queryParams.join('&');
      window.history.replaceState({}, '', newUrl);
  });


  var purchaseOrderTable = $('#purchase_orders_table').DataTable({
    responsive: true,
    processing: true,
    serverSide: true,
    stateSave: true, // Automatically save state in sessionStorage
    "language": {
      "searchPlaceholder": "Search Purchase Order"
    },
    "ajax": $(location).attr('href'),
    "columnDefs": [
      { "targets": 0 },
      { "targets": 1 },
      { "targets": 2 },
      { "targets": 3 },
      { "targets": 4 },
      { "targets": 5 },
      { "targets": 6 },
      { "targets": 7 },
      { "targets": 8 }
    ],
    "order": [[0, "desc"]], // Order by the "Updated At" column
    "buttons": [
      {
        "extend": 'csv',
        "text": '',
        "title": 'Fauree : Export data into CSV',
        "exportOptions": {
          "columns": ':visible'
        },
        "className": 'btn text-center cstm-btn bi bi-filetype-csv'
      },
      {
        "extend": 'copy',
        "text": '',
        "className": 'btn text-center cstm-btn bi bi-clipboard'
      }
    ],
    "dom": 'lfQtiBp'
  });

  // URL parameter handling
  var urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has('search')) {
    // Apply search parameter
    purchaseOrderTable.search(urlParams.get('search')).draw();
  }

  if (urlParams.has('order')) {
    // Apply order parameter
    var orderParams = urlParams.get('order').split(',');
    purchaseOrderTable.order(orderParams[0], orderParams[1]).draw();
  }

  // Save state to URL when state changes
  purchaseOrderTable.on('stateSaveParams.dt', function(e, settings, data) {
    var search = purchaseOrderTable.search();
    var queryParams = [];
    if (search) {
      queryParams.push('search=' + search);
    }
    var newUrl = window.location.pathname + '?' + queryParams.join('&');
    window.history.replaceState({}, '', newUrl);
  });

  var deliveryConfirmationsTable = $('#delivery_confirmations_table').DataTable({
    responsive: true,
    processing: true,
    serverSide: true,
    searchDelay: 1000,
    stateSave: true,
    "language": {
      searchPlaceholder: "Search Delivery Confirmation"
    },
    "ajax": $(location).attr('href'),
    "columnDefs": [{
      "targets": 0
    }, {
      "targets": 1
    }, {
      "targets": 2
    }, {
      "targets": 3
    }, {
      "targets": 4
    }, {
      "targets": 5
    }, {
      "targets": 6
    }, {
      "targets": 7,
    }, {
      "targets": 8,
    }],
    "order": [[8, "desc"]],
    "buttons": [{
      "extend": 'csv',
      "text": '',
      "title": 'Fauree : Export data into CSV',
      "exportOptions": {
        "columns": ':visible'
      },
      "className": 'btn text-center cstm-btn bi bi-filetype-csv'
    }, {
      "extend": 'copy',
      "text": '',
      "className": 'btn text-center cstm-btn bi bi-clipboard'
    }],
    "dom": 'lfQtiBp'
  });
  
  // URL parameter handling
  var urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has('search')) {
    deliveryConfirmationsTable.search(urlParams.get('search')).draw();
  }
  
  // Save state to URL when state changes
  deliveryConfirmationsTable.on('stateSaveParams.dt', function(e, settings, data) {
    var search = deliveryConfirmationsTable.search();
    var queryParams = [];
    if (search) {
      queryParams.push('search=' + search);
    }
   
    var newUrl = window.location.pathname + '?' + queryParams.join('&');
    window.history.replaceState({}, '', newUrl);
  });

  var anchorProgramTable = $('#anchor_program_table').DataTable({
    "responsive": true,
    "processing": true,
    serverSide: true,
    stateSave: true, // Automatically save state in sessionStorage
    "language": {
      searchPlaceholder: "Search Program"
    },
    "ajax": $(location).attr('href'),
    "columnDefs": [{
      "targets": 0, "className": "hide"
    }, {
      "targets": 1
    }, {
      "targets": 2
    }, {
      "targets": 3
    }, {
      "targets": 4,
      "orderable": false,
      "className": "text-center"
    },{
      "targets": 5
    }, {
      "targets": 6
    }, {
      "targets": 7
    }, {
      "targets": 8
    }, {
      "targets": 9
    }],
    "order": [[0, "desc"]],
    "buttons": [{
      "extend": 'csv',
      "text": '',
      "title": 'Fauree : Export data into CSV',
      "exportOptions": {
        "columns": ':visible'
      },
      "className": 'btn text-center cstm-btn bi bi-filetype-csv'
    }, {
      "extend": 'copy',
      "text": '',
      "className": 'btn text-center cstm-btn bi bi-clipboard'
    }],
    "dom": 'lfQtiBp',
  });
  var urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has('search')) {
      // Apply search parameter
      anchorProgramTable.search(urlParams.get('search')).draw();
  }
  if (urlParams.has('order')) {
      // Apply order parameter
      var orderParams = urlParams.get('order').split(',');
      anchorProgramTable.order(orderParams[0], orderParams[1]).draw();
  }

  // Save state to URL when state changes
  anchorProgramTable.on('stateSaveParams.dt', function(e, settings, data) {
      var search = anchorProgramTable.search();
      var queryParams = [];
      if (search) {
          queryParams.push('search=' + search);
      }
      var newUrl = window.location.pathname + '?' + queryParams.join('&');
      window.history.replaceState({}, '', newUrl);
  });

  var supplierLocations = $('#supplier_locations').DataTable({
    "responsive": true,
    "processing": true,
    stateSave: true, // Automatically save state in sessionStorage
    "language": {
      searchPlaceholder: "Search Location"
    },

    "columnDefs": [{
      "targets": 0
    }, {
      "targets": 1
    }, {
      "targets": 2
    }, {
      "targets": 3
    }, {
      "targets": 4,
      "orderable": false,
      "className": "text-center"
    }],
    "buttons": [{
      "extend": 'csv',
      "text": '',
      "title": 'Fauree : Export data into CSV',
      "exportOptions": {
        "columns": ':visible'
      },
      "className": 'btn text-center cstm-btn bi bi-filetype-csv'
    }, {
      "extend": 'copy',
      "text": '',
      "className": 'btn text-center cstm-btn bi bi-clipboard'
    }],
    "dom": 'lfQtiBp',
  });
  var urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has('search')) {
      // Apply search parameter
      supplierLocations.search(urlParams.get('search')).draw();
  }
  if (urlParams.has('order')) {
      // Apply order parameter
      var orderParams = urlParams.get('order').split(',');
      supplierLocations.order(orderParams[0], orderParams[1]).draw();
  }

  // Save state to URL when state changes
  supplierLocations.on('stateSaveParams.dt', function(e, settings, data) {
      var search = supplierLocations.search();
      var queryParams = [];
      if (search) {
          queryParams.push('search=' + search);
      }
      var newUrl = window.location.pathname + '?' + queryParams.join('&');
      window.history.replaceState({}, '', newUrl);
  });


  var eprdataTable = $('#early_payment_requests_table').DataTable({
    responsive: true,
    processing: true,
    serverSide: true,
    searchDelay: 1000,
    stateSave: true, // Automatically save state in sessionStorage
    "language": {
      searchPlaceholder: "Search EPR"
    },


    "columnDefs": [{
      "targets": 0
    }, {
      "targets": 1
    }, {
      "targets": 2
    }, {
      "targets": 3
    }, {
      "targets": 4
    }, {
      "targets": 5
    }, {
      "targets": 6
    }, {
      "targets": 7
    }, {
      "targets": 8,
      "orderable": false,
      "className": "text-center"
    }, {
      "targets": 9,
      "className": "hide"
    }],
    "order": [[9, "desc"]],
    "buttons": [{
      "extend": 'csv',
      "text": '',
      "title": 'Fauree : Export data into CSV',
      "exportOptions": {
        "columns": ':visible'
      },
      "className": 'btn text-center cstm-btn bi bi-filetype-csv'
    }, {
      "extend": 'copy',
      "text": '',
      "className": 'btn text-center cstm-btn bi bi-clipboard'
    }],
    "dom": 'lfQtiBp',
  });

  // Check for URL parameters and apply them to DataTable
  var urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has('search')) {
      // Apply search parameter
      eprdataTable.search(urlParams.get('search')).draw();
  }
  if (urlParams.has('order')) {
      // Apply order parameter
      var orderParams = urlParams.get('order').split(',');
      eprdataTable.order(orderParams[0], orderParams[1]).draw();
  }

  // Save state to URL when state changes
  eprdataTable.on('stateSaveParams.dt', function(e, settings, data) {
      var search = eprdataTable.search();
      var queryParams = [];
      if (search) {
          queryParams.push('search=' + search);
      }
      var newUrl = window.location.pathname + '?' + queryParams.join('&');
      window.history.replaceState({}, '', newUrl);
  });

  var eprFindataTable = $('#financier_early_payment_requests_table').DataTable({
    responsive: true,
    processing: true,
    serverSide: true,
    searchDelay: 1000,
    stateSave: true, // Automatically save state in sessionStorage
    "language": {
      searchPlaceholder: "Search EPR"
    },


    "columnDefs": [{
      "targets": 0
    }, {
      "targets": 1
    }, {
      "targets": 2
    }, {
      "targets": 3
    }, {
      "targets": 4
    }, {
      "targets": 5
    }, {
      "targets": 6
    }, {
      "targets": 7
    }, {
      "targets": 8
    }, {
      "targets": 9,
      "orderable": false,
      "className": "text-center"
    }, {
      "targets": 10,
      "className": "hide"
    }],
    "order": [[10, "desc"]],
    "buttons": [{
      "extend": 'csv',
      "text": '',
      "title": 'Fauree : Export data into CSV',
      "exportOptions": {
        "columns": ':visible'
      },
      "className": 'btn text-center cstm-btn bi bi-filetype-csv',
      action: async function (e, dt, button, config) {
        await createDownloadLogIfAllowed('financiers');
        $.fn.dataTable.ext.buttons.csvHtml5.action.call(this, e, dt, button, config);
      }
    }, {
      "extend": 'copy',
      "text": '',
      "className": 'btn text-center cstm-btn bi bi-clipboard'
    }],
    "dom": 'lfQtiBp',
  });

  // Check for URL parameters and apply them to DataTable
  var urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has('search')) {
      // Apply search parameter
      eprFindataTable.search(urlParams.get('search')).draw();
  }
  if (urlParams.has('order')) {
      // Apply order parameter
      var orderParams = urlParams.get('order').split(',');
      eprFindataTable.order(orderParams[0], orderParams[1]).draw();
  }

  // Save state to URL when state changes
  eprFindataTable.on('stateSaveParams.dt', function(e, settings, data) {
      var search = eprFindataTable.search();
      var queryParams = [];
      if (search) {
          queryParams.push('search=' + search);
      }
      var newUrl = window.location.pathname + '?' + queryParams.join('&');
      window.history.replaceState({}, '', newUrl);
  });


  var discountRuleTable = $('#discount_rule_table').DataTable({
    responsive: true,
    processing: true,
    serverSide: true,
    searchDelay: 1000,
    stateSave: true, // Automatically save state in sessionStorage
    "language": {
      searchPlaceholder: "Search Discount Rule"
    },
    "columnDefs": [{
      "targets": 0
    }, {
      "targets": 1
    }, {
      "targets": 2
    }, {
      "targets": 3
    }, {
      "targets": 4
    }, {
      "targets": 5
    }, {
      "targets": 6
    }, {
      "targets": 7
    }, {
      "targets": 8
    }, {
      "targets": 9,
      "orderable": false,
      "className": "text-center"
    }],
    "buttons": [{
      "extend": 'csv',
      "text": '',
      "title": 'Fauree : Export data into CSV',
      "exportOptions": {
        "columns": ':visible'
      },
      "className": 'btn text-center cstm-btn bi bi-filetype-csv'
    }, {
      "extend": 'copy',
      "text": '',
      "className": 'btn text-center cstm-btn bi bi-clipboard'
    }],
    "dom": 'lfQtiBp',
  });

  // Check for URL parameters and apply them to DataTable
  var urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has('search')) {
      // Apply search parameter
      discountRuleTable.search(urlParams.get('search')).draw();
  }
  if (urlParams.has('order')) {
      // Apply order parameter
      var orderParams = urlParams.get('order').split(',');
      discountRuleTable.order(orderParams[0], orderParams[1]).draw();
  }

  // Save state to URL when state changes
  discountRuleTable.on('stateSaveParams.dt', function(e, settings, data) {
      var search = discountRuleTable.search();
      var queryParams = [];
      if (search) {
          queryParams.push('search=' + search);
      }
      var newUrl = window.location.pathname + '?' + queryParams.join('&');
      window.history.replaceState({}, '', newUrl);
  });


  $('#admin_discount_rule_table').DataTable({

    responsive: true,
    processing: true,
    serverSide: true,
    searchDelay: 1000,
  "language": {
      searchPlaceholder: "Search Discount Rule"
    },
    "ajax": $(location).attr('href'),

    "columnDefs": [{
      "targets": 0
    }, {
      "targets": 1
    }, {
      "targets": 2
    }, {
      "targets": 3
    }, {
      "targets": 4
    }, {
      "targets": 5,
      "orderable": false,
      "className": "text-center"
    }],
    "buttons": [{
      "extend": 'csv',
      "text": '',
      "title": 'Fauree : Export data into CSV',
      "exportOptions": {
        "columns": ':visible'
      },
      "className": 'btn text-center cstm-btn bi bi-filetype-csv',
      action: async function (e, dt, button, config) {
        await createDownloadLogIfAllowed('admin/discount_rules');
        $.fn.dataTable.ext.buttons.csvHtml5.action.call(this, e, dt, button, config);
      }
    }, {
      "extend": 'copy',
      "text": '',
      "className": 'btn text-center cstm-btn bi bi-clipboard'
    }],
    "dom": 'lfQtiBp',
  });


  var financingRulesTable = $('#financing_rule_table').DataTable({
    responsive: true,
    processing: true,
    serverSide: true,
    searchDelay: 1000,
    stateSave: true, // Automatically save state in sessionStorage
    "language": {
      searchPlaceholder: "Search Financing Rule"
    },
    "ajax": $(location).attr('href'),

    "columnDefs": [{
      "targets": 0,
      "width": "10%",
      "className": "text-center"
    }, {
      "targets": 1,
      "orderable": false,
      "width": "15%",
      "className": "text-center"
    }, {
      "targets": 2,
      "orderable": false,
      "width": "20%",
      "className": "text-center"
    }, {
      "targets": 3,
      "orderable": false,
      "width": "15%",
      "className": "text-center"
    }, {
      "targets": 4,
      "className": "text-center"
    }, {
      "targets": 5,
      "width": "15%",
      "orderable": false,
      "className": "text-center"
    }, {
      "targets": 6,
      "className": "hide"
    }],
    "order": [[6, "desc"]],
    "buttons": [{
      "extend": 'csv',
      "text": '',
      "title": 'Fauree : Export data into CSV',
      "exportOptions": {
        "columns": ':visible'
      },
      "className": 'btn text-center cstm-btn bi bi-filetype-csv'
    }, {
      "extend": 'copy',
      "text": '',
      "className": 'btn text-center cstm-btn bi bi-clipboard'
    }],
    "dom": 'lfQtiBp',
  });
  // Check for URL parameters and apply them to DataTable
  var urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has('search')) {
      // Apply search parameter
      financingRulesTable.search(urlParams.get('search')).draw();
  }
  if (urlParams.has('order')) {
      // Apply order parameter
      var orderParams = urlParams.get('order').split(',');
      financingRulesTable.order(orderParams[0], orderParams[1]).draw();
  }

  // Save state to URL when state changes
  financingRulesTable.on('stateSaveParams.dt', function(e, settings, data) {
      var search = financingRulesTable.search();
      var queryParams = [];
      if (search) {
          queryParams.push('search=' + search);
      }
      var newUrl = window.location.pathname + '?' + queryParams.join('&');
      window.history.replaceState({}, '', newUrl);
  });


  $('#admin_financing_rule_table').DataTable({

    responsive: true,
    processing: true,
    serverSide: true,
    searchDelay: 1000,
    "language": {
      searchPlaceholder: "Search Financing Rule"
    },
    "ajax": $(location).attr('href'),

    "columnDefs": [{
      "targets": 0
    }, {
      "targets": 1
    }, {
      "targets": 2
    }, {
      "targets": 3
    }, {
      "targets": 4
    }, {
      "targets": 5
    }, {
      "targets": 6,
      "orderable": false,
      "className": "text-center"
    }],
    "buttons": [{
      "extend": 'csv',
      "text": '',
      "title": 'Fauree : Export data into CSV',
      "exportOptions": {
        "columns": ':visible'
      },
      "className": 'btn text-center cstm-btn bi bi-filetype-csv',
      action: async function (e, dt, button, config) {
        await createDownloadLogIfAllowed('admin/financing_rules');
        $.fn.dataTable.ext.buttons.csvHtml5.action.call(this, e, dt, button, config);
      }
    }, {
      "extend": 'copy',
      "text": '',
      "className": 'btn text-center cstm-btn bi bi-clipboard'
    }],
    "dom": 'lfQtiBp',
  });

  var creditLimitTable = $('#credit_limit_table').DataTable({
    responsive: true,
    processing: true,
    serverSide: true,
    searchDelay: 1000,
    stateSave: true, // Automatically save state in sessionStorage
    "language": {
      searchPlaceholder: "Search Credit Limit"
    },
    "ajax": $(location).attr('href'),
    "columnDefs": [{
      "targets": 0,
      "width": "10%",
      "className": "text-center"
    }, {
      "targets": 1,
      "orderable": false,
      "width": "15%",
      "className": "text-center"
    }, {
      "targets": 2,
      "orderable": false,
      "width": "20%",
      "className": "text-center"
    }, {
      "targets": 3,
      "orderable": false,
      "width": "15%",
      "className": "text-center"
    }, {
      "targets": 4,
      "className": "text-center"
    }, {
      "targets": 5,
      "width": "15%",
      "orderable": false,
      "className": "text-center"
    }],
    "buttons": [{
      "extend": 'csv',
      "text": '',
      "title": 'Fauree : Export data into CSV',
      "exportOptions": {
        "columns": ':visible'
      },
      "className": 'btn text-center cstm-btn bi bi-filetype-csv'
    }, {
      "extend": 'copy',
      "text": '',
      "className": 'btn text-center cstm-btn bi bi-clipboard'
    }],
    "dom": 'lfQtiBp',
  });
  // Check for URL parameters and apply them to DataTable
  var urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has('search')) {
      // Apply search parameter
      creditLimitTable.search(urlParams.get('search')).draw();
  }
  if (urlParams.has('order')) {
      // Apply order parameter
      var orderParams = urlParams.get('order').split(',');
      creditLimitTable.order(orderParams[0], orderParams[1]).draw();
  }

  // Save state to URL when state changes
  creditLimitTable.on('stateSaveParams.dt', function(e, settings, data) {
      var search = creditLimitTable.search();
      var queryParams = [];
      if (search) {
          queryParams.push('search=' + search);
      }
      var newUrl = window.location.pathname + '?' + queryParams.join('&');
      window.history.replaceState({}, '', newUrl);
  });

 
  var tijarahTable = $('#tijarah_table').DataTable({
    "responsive": true,
    "processing": true,
    serverSide: true,
    stateSave: true, // Automatically save state in sessionStorage
    "language": {
      searchPlaceholder: "Search Tijarah Model"
    },
    "ajax": $(location).attr('href'),
    "columnDefs": [{
      "targets": 0
    }, {
      "targets": 1
    }, {
      "targets": 2
    }, {
      "targets": 3
    }, {
      "targets": 4
    }, {
      "targets": 5
    }, {
      "targets": 6
    }, {
      "targets": 7,
      "className": "hide"
    }],
    "order": [[7, "desc"]],
    "buttons": [{
      "extend": 'csv',
      "text": '',
      "title": 'Fauree : Export data into CSV',
      "exportOptions": {
        "columns": ':visible'
      },
      "className": 'btn text-center cstm-btn bi bi-filetype-csv'
    }, {
      "extend": 'copy',
      "text": '',
      "className": 'btn text-center cstm-btn bi bi-clipboard'
    }],
    "dom": 'lfQtiBp',
  });
  var urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has('search')) {
      // Apply search parameter
      tijarahTable.search(urlParams.get('search')).draw();
  }
  if (urlParams.has('order')) {
      // Apply order parameter
      var orderParams = urlParams.get('order').split(',');
      tijarahTable.order(orderParams[0], orderParams[1]).draw();
  }

  // Save state to URL when state changes
  tijarahTable.on('stateSaveParams.dt', function(e, settings, data) {
      var search = tijarahTable.search();
      var queryParams = [];
      if (search) {
          queryParams.push('search=' + search);
      }
      var newUrl = window.location.pathname + '?' + queryParams.join('&');
      window.history.replaceState({}, '', newUrl);
  });


  $('#admin_credit_limit_table').DataTable({
    responsive: true,
    processing: true,
    serverSide: true,
    searchDelay: 1000,
    "language": {
      searchPlaceholder: "Search Credit Limit"
    },
    "ajax":$(location).attr('href'),
    "columnDefs": [{
      "targets": 0
    }, {
      "targets": 1
    }, {
      "targets": 2
    }, {
      "targets": 3
    }, {
      "targets": 4
    }, {
      "targets": 5
    }, {
      "targets": 6,
      "orderable": false,
      "className": "text-center"
    }],
    "buttons": [{
      "extend": 'csv',
      "text": '',
      "title": 'Fauree : Export data into CSV',
      "exportOptions": {
        "columns": ':visible'
      },
      "className": 'btn text-center cstm-btn bi bi-filetype-csv',
      action: async function (e, dt, button, config) {
        await createDownloadLogIfAllowed('admin/credit_limits');
        $.fn.dataTable.ext.buttons.csvHtml5.action.call(this, e, dt, button, config);
      }
    }, {
      "extend": 'copy',
      "text": '',
      "className": 'btn text-center cstm-btn bi bi-clipboard'
    }],
    "dom": 'lfQtiBp',
  });
  
  var usersTables = $('#manage_user_table').DataTable({
    responsive: true,
    processing: true,
    serverSide: true,
    searchDelay: 1000,
    stateSave: true, // Automatically save state in sessionStorage
    "language": {
      searchPlaceholder: "Search User"
    },
    "ajax": $(location).attr('href'),
    "columnDefs": [{
      "targets": 0
    }, {
      "targets": 1
    }, {
      "targets": 2
    }, {
      "targets": 3
    }, {
      "targets": 4,
      "orderable": false
    }, {
      "targets": 5,
      "orderable": false,
      "className": "text-center"
    }],
    "buttons": [{
      "extend": 'csv',
      "text": '',
      "title": 'Fauree : Export data into CSV',
      "exportOptions": {
        "columns": ':visible'
      },
      "className": 'btn text-center cstm-btn bi bi-filetype-csv'
    }, {
      "extend": 'copy',
      "text": '',
      "className": 'btn text-center cstm-btn bi bi-clipboard'
    }],
    "dom": 'lfQtiBp',
  });
  // Check for URL parameters and apply them to DataTable
  var urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has('search')) {
      // Apply search parameter
      usersTables.search(urlParams.get('search')).draw();
  }

  // Save state to URL when state changes
  usersTables.on('stateSaveParams.dt', function(e, settings, data) {
      var search = usersTables.search();
      var queryParams = [];
      if (search) {
          queryParams.push('search=' + search);
      }
      var newUrl = window.location.pathname + '?' + queryParams.join('&');
      window.history.replaceState({}, '', newUrl);
  });


  var rolesTable = $('#role_table').DataTable({
    responsive: true,
    processing: true,
    serverSide: true,
    searchDelay: 1000,
    stateSave: true, // Automatically save state in sessionStorage
    "language": {
      searchPlaceholder: "Search Role"
    },
    "ajax": $(location).attr('href'),
  
    "columnDefs": [{
      "targets": 0,
      "className": "hide"
    }, {
      "targets": 1
    }, {
      "targets": 2,
      "orderable": false
    }, {
      "targets": 3,
      "orderable": false
    }, {
      "targets": 4,
      "orderable": false,
      "className": "text-center"
    }],
    "order": [[0, "desc"]],
    "buttons": [{
      "extend": 'csv',
      "text": '',
      "title": 'Fauree : Export data into CSV',
      "exportOptions": {
        "columns": ':visible'
      },
      "className": 'btn text-center cstm-btn bi bi-filetype-csv'
    }, {
      "extend": 'copy',
      "text": '',
      "className": 'btn text-center cstm-btn bi bi-clipboard'
    }],
    "dom": 'lfQtiBp',
  });
  // Check for URL parameters and apply them to DataTable
  var urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has('search')) {
      // Apply search parameter
      rolesTable.search(urlParams.get('search')).draw();
  }

  // Save state to URL when state changes
  rolesTable.on('stateSaveParams.dt', function(e, settings, data) {
      var search = rolesTable.search();
      var queryParams = [];
      if (search) {
          queryParams.push('search=' + search);
      }

      var newUrl = window.location.pathname + '?' + queryParams.join('&');
      window.history.replaceState({}, '', newUrl);
  });


  $('#inbound_request_logs_table_imports').DataTable({
    "responsive": true,
    "processing": true,
    "language": {
      searchPlaceholder: "Search Log"
    },

    "columnDefs": [{
      "targets": 0,
      "width": "5%"
    }, {
      "targets": 1
    }, {
      "targets": 2
    }, {
      "targets": 3
    }, {
      "targets": 4
    }, {
      "targets": 5,
      "width": "10%"
    }],
    "buttons": [{
      "extend": 'csv',
      "text": 'Export',
      "title": 'Fauree : Export data into CSV',
      "exportOptions": {
        "columns": ':visible'
      },
      "className": 'btn text-center cstm-btn bi bi-filetype-csv'
    }, {
      "extend": 'copy',
      "text": 'Copy',
      "title": 'Fauree : Copy data on Clipboard',
      "className": 'btn text-center cstm-btn bi bi-clipboard'
    }],
    "dom": 'lfQtiBp',
  });

  $('#import_logs_table').DataTable({
    "responsive": true,
    "processing": true,
    "language": {
      searchPlaceholder: "Search Log"
    },

    "columnDefs": [{
      "targets": 0,
      "width": "10%"
    },{
      "targets": 1,
      "width": "30%",
      "orderable": false
    }, {
      "targets": 2,
      "width": "20%",
      "orderable": false
    }, {
      "targets": 3,
      "width": "30%",
      "type": "date"
    }, {
      "targets": 4,
      "width": "10%",
      "orderable": false
    }],
    "order": [[3, "desc"]],
    "buttons": [{
      "extend": 'csv',
      "text": 'Export',
      "title": 'Fauree : Export data into CSV',
      "exportOptions": {
        "columns": ':visible'
      },
      "className": 'btn text-center cstm-btn bi bi-filetype-csv'
    }, {
      "extend": 'copy',
      "text": 'Copy',
      "title": 'Fauree : Copy data on Clipboard',
      "className": 'btn text-center cstm-btn bi bi-clipboard'
    }],
    "dom": 'lfQtiBp',
  });

  $('#inbound_request_logs_table_imports').DataTable({
    "responsive": true,
    "processing": true,
    "language": {
      searchPlaceholder: "Search Log"
    },

    "columnDefs": [{
      "targets": 0,
      "width": "5%"
    }, {
      "targets": 1
    }, {
      "targets": 2
    }, {
      "targets": 3
    }, {
      "targets": 4
    }, {
      "targets": 5,
      "width": "10%"
    }],
    "buttons": [{
      "extend": 'csv',
      "text": 'Export',
      "title": 'Fauree : Export data into CSV',
      "exportOptions": {
        "columns": ':visible'
      },
      "className": 'btn text-center cstm-btn bi bi-filetype-csv'
    }, {
      "extend": 'copy',
      "text": 'Copy',
      "title": 'Fauree : Copy data on Clipboard',
      "className": 'btn text-center cstm-btn bi bi-clipboard'
    }],
    "dom": 'lfQtiBp',
  });

  $(".loading-show").on("click", function() {
    var file = $("#imports_file").val();
    var type = $("#imports_type").val();
    if(file.length != 0 && type.length != 0 ) {
      $("#imports").submit();
      $('#loading').show();
      $('.loading-show').prop('disabled', true);
    }
  });

function modifyEntity() {
  let $amountField = $(`#entity-total-amount`)
  let $amountFieldOfDeliveryConfirmation = $(`#delivery_confirmation_amount`)
  let $amountFieldWithcurrency = $('#total-amount-with-currencey')
  let margin_percentage = $(`#entity-total-amount, #delivery_confirmation_amount`).data('attr-margin');
  margin_percentage = margin_percentage ? parseFloat(margin_percentage) : 0;
  let margin_multiplier = 1 - (margin_percentage / 100)

  let currentAmount = 0

  $(".item-fields .item-field-row").each(function () {
    var value = parseFloat($(this).find(".item-entity-total-amount").val());
    if(value <= 0){
      value += parseFloat($(this).find(".total-amount").val());
    }
    // console.log("total-amount", $(this).find(".total-amount").val())
    // console.log("value", value)
    let isDestroy = $(this).find(".isdestroy").val();
    if (!isNaN(value) && isDestroy !== "1") { currentAmount += value; }
    if(isDestroy === "1") {$(this).find(".additional-prices").hide();}
  });

  $(`.formula-modal .formula-modal-fields`).each(function(index, element) {
    const $container = $(element);
    const $inputs = $container.find("input");
    const destroy = $inputs.first().val();
    const formulaMethod = $container.find("select").first().val();
    const amount = parseFloat($inputs.eq(2).val());
    currentAmount += (destroy === 'false' && formulaMethod === '+') ? amount : ((destroy === 'false' && formulaMethod === '-') ? -amount : 0);
  });

  let currentAmountWithMargin =  currentAmount * margin_multiplier;

  $amountField.val(currentAmountWithMargin.toFixed(2));
  $amountFieldOfDeliveryConfirmation.val(currentAmountWithMargin.toFixed(2));
  $amountFieldWithcurrency.text(currentAmount.toFixed(2));
}

function modifyItemEntity(event) {
  let event_id = event.target.id.split("-").pop();
  var tax_included = $(`#tax-included-${event_id}`).prop('checked');
  var tax_calculation = $(`#tax-calculation-${event_id}`).prop('checked');
  let entityAmount = 0;
  let quantity_val = $(`#quantity-${event_id}`).val()
  let price_val = $(`#price-${event_id}`).val()
  let quantity = quantity_val != "" ? quantity_val : 1
  let price = price_val != "" ? price_val : 1

  if(quantity_val == "" && price_val == ""){
    quantity = 0
    price = 0
  }
  
  let currentAmount =  parseFloat(quantity) * parseFloat(price)
  let total_amount = $(`#total-amount-${event_id}`);

  if (tax_included || tax_calculation){
    currentAmount = parseFloat(total_amount.val());
  }else{
    total_amount.val(parseFloat(currentAmount).toFixed(2));
  }

  $(`.item-formula-modal-fields-${event_id}`).each(function(index, element) {
    const $container = $(element);
    const $inputs = $container.find("input");
    const destroy = $inputs.first().val();
    const formulaMethod = $container.find("select").first().val();
    const amount = parseFloat($inputs.eq(2).val());
    console.log("formula method is ", formulaMethod)
    entityAmount += (destroy === 'false' && formulaMethod === '+') ? amount : ((destroy === 'false' && formulaMethod === '-') ? -amount : 0);
  });

  if(entityAmount){
    currentAmount += parseFloat(entityAmount.toFixed(2));
  }else {
    currentAmount = 0;
  }
  $(`#item-entity-total-amount-${event_id}`).val(parseFloat(currentAmount).toFixed(2));
}

$(".modify-entity").on("click", function(event) {
      modifyEntity()
});
$(document).on('click', '.modify-item-entity', function(event) {
  modifyItemEntity(event)
  modifyEntity();
});

$(document).on('keyup change', '.quantity-price, .price-quantity, .total-amount', function(event) {
  modifyItemEntity(event)
  modifyEntity();   
});

$(document).on('change', '.tax-included, .tax-calculation', function(event) {
  modifyItemEntity(event);
  modifyEntity();
})

$(document).on('click', '.item-removed-event', function(event) {
  setTimeout(function() {
    modifyEntity()
  }, 0);
});

$(".headers-event").on("click", function(event) {
  
  let $headerContainer = $(".headers-area").empty()
  $(".headers-modal .additional-headers").each(function(index,element) {
    let $fields = $(element)
    let title = $($fields.find(".header-title")).val()
    let isDestroy = $($fields.find(".is-destroy")).val()
    let $styledElement = $("<span></span>").addClass("styled-header").text(title);
    if(isDestroy === "false" && title.trim().length > 0){$headerContainer.append($styledElement);} 
  })
})

$(document).on('click', '.add_fields', function(event) {
  event.preventDefault()
  handleAddFields(event.target,event)
  select2();
  select2_select();
});
  
function handleAddFields(link, e) {
  // Stop the function from executing if a link or event were not passed into the function.
  if (!link || !e) return
  // Prevent the browser from following the URL.
  e.preventDefault()
  // Save a unique timestamp to ensure the key of the associated array is unique.
  let time = new Date().getTime()
  // Save the data id attribute into a variable. This corresponds to `new_object.object_id`.
  let linkId = link.dataset.id
  // Create a new regular expression needed to find any instance of the `new_object.object_id` used in the fields data attribute if there's a value in `linkId`.
  let regexp = linkId ? new RegExp(linkId, 'g') : null
  // Replace all instances of the `new_object.object_id` with `time`, and save markup into a variable if there's a value in `regexp`.
  let newFields = regexp ? link.dataset.fields.replace(regexp, time) : null
  // Add the new markup to the form if there are fields to add.
  newFields ? link.insertAdjacentHTML('beforebegin', newFields) : null
}

async function createDownloadLogIfAllowed(appModule) {
  var currentUserID = $("#user-container").attr("data-current-user-id");

  const response = await $.ajax({
    url: '/admin/download_logs/create_download_log',
    type: 'GET',
    data: { current_user_id: currentUserID, app_module: appModule},
    dataType: 'json'
  });

  return response;
}
$(document).on('click', '.remove_fields', function(event) {
  handleRemoveFields(event.target,event)
});

function handleRemoveFields(link, e) {
  // Stop the function from executing if a link or event were not passed into the function.

  if (!link || !e) return
  // Prevent the browser from following the URL.
  e.preventDefault()
  // Find the parent wrapper for the set of nested fields.
  let fieldParent = link.closest('.nested-fields')
  // If there is a parent wrapper, find the hidden delete field.
  let deleteField = fieldParent
    ? fieldParent.querySelector('input[type="hidden"]')
    : null
  // If there is a delete field, update the value to `1` and hide the corresponding nested fields.
  if (deleteField) {
    let additional_prices_amount_id = $("#"+fieldParent.id).find('.additional-prices-amount')[0].id;
    $("#"+additional_prices_amount_id).val("0");
    console.log("additional_prices_amount_id", additional_prices_amount_id)
    deleteField.value = 1
    fieldParent.style.display = 'none'
  }
}

$(document).on('click', '.item_remove_fields', function(event) {
  if ($('.item_remove_fields:visible').length > 1) {
    handleItemRemoveFields(event.target, event)
  } else {
    alert('Record should contain atleast 1 item')
  }
});

function handleItemRemoveFields(link, e) {
  // Stop the function from executing if a link or event were not passed into the function.

  if (!link || !e) return
  // Prevent the browser from following the URL.
  e.preventDefault()
  // Find the parent wrapper for the set of nested fields.
  let fieldParent = link.closest('.fields, .nested-fields')
  // If there is a parent wrapper, find the hidden delete field.
  let deleteField = fieldParent
    ? fieldParent.querySelector('input[type="hidden"]')
    : null
  // If there is a delete field, update the value to `1` and hide the corresponding nested fields.
  if (deleteField) {
    deleteField.value = 1
    fieldParent.style.display = 'none'
    let quantity = $("#"+fieldParent.id).find('.quantity-price')[0].id;
    let price = $("#"+fieldParent.id).find('.price-quantity')[0].id;
    let total_amount = $("#"+fieldParent.id).find('.total-amount')[0].id;
    $("#"+quantity).val("0");
    $("#"+price).val("0");
    $("#"+total_amount).val("0");

    let event_id = e.target.id.split("-").pop();

    $(`.item-formula-modal-fields-${event_id}`).each(function(index, element) {
      const $container = $(element);
      const $inputs = $container.find("input");
      var destroy = $inputs.first();
      let additional_prices_amount_id = $container.find('.additional-prices-amount')[0].id;
      $("#"+additional_prices_amount_id).val("0");
      destroy.value = 1
    });
  }
}

$(document).on('change', '#invoice-customer-select', function(event) {
  var customerId = $(this).val();
  var organizationSelect = $('#invoice-organisation-select');
  organizationSelect.empty();
  organizationSelect.append($('<option>')
    .text('Please select customer first')
    .attr('value', '')  // Set an empty value for the prompt option
  );
  $.ajax({
    url: '/admin/customer_organisations',
    type: 'GET',
    data: { customer_id: customerId },
    dataType: 'json',
    success: function(data) {
      organizationSelect.empty();
      $.each(data, function(key, value) {
        organizationSelect.append($('<option>').text(`${value.name}, ${value.number}`).attr('value', value.id));
      });
    }
  });
});

$(document).on('change', '#discount-rule-suppliers-select', function(event) {
  var supplierId = $(this).val();
  var siteSelect = $('#discount-rule-sites-select');
  siteSelect.empty();
  siteSelect.append($('<option>')
    .text('Select Site')
    .attr('value', '')  // Set an empty value for the prompt option
  );

  $.ajax({
    url: '/admin/supplier_sites',
    type: 'GET',
    data: { supplier_id: supplierId },
    dataType: 'json',
    success: function(data) {
      $.each(data, function(key, value) {
        siteSelect.append($('<option>').text(`${value.name}, ${value.number}`).attr('value', value.id));
      });
    }
  });
});

$(document).ready(function() {
  // Open the collapse element if it contains an active link
  const activeLink = $(".nav-item .nav-link.active");
  if (activeLink.length > 0) {
    const collapseElement = activeLink.closest(".collapse");
    if (collapseElement.length > 0) {
      collapseElement.addClass("show");
    }
  }

  // Handle click on nav links within the collapse
  $(".collapse .nav-link").on('click', function(e) {
    e.stopPropagation();  // Prevent the collapse from closing
  });

  // Handle click on main nav links (dropdowns)
  $(".nav-item.dropdown > .nav-link").on('click', function(e) {
    const target = $(this).attr('href');  // Get the target collapse element

    // Toggle the collapse manually
    if ($(target).hasClass('show')) {
      $(target).collapse('hide');
    } else {
      $(target).collapse('show');
    }

    // Close other open collapse elements
    $(".collapse").not(target).collapse('hide');
  });
});


$(document).on('change', '.confirmation-before-change', function(event) {
  const changingState = $(this).prop('checked');

  if(!confirm("Are you sure you want to change this settings?")) {
    $(this).prop('checked', !changingState); // reverting the change if response is no
  }
});

$(document).on('change', '.confirmation-before-change-password-expiry', function(event) {
  const changingState = $(this).prop('checked');

  const message = changingState 
    ? "Changing this will override the password expiry duration set by the admin. Are you sure you want to customize the settings for this subuser?" 
    : "Are you sure you want to disable the custom password expiry settings for this subuser? This will revert to the admin configuration";

  if (!confirm(message)) {
    $(this).prop('checked', !changingState);
  }
});

$(document).on('change', '#sftp_check', function(event) {
  if ($(this).prop('checked')) {
    $("#sftp_box").removeClass("d-none");
  } else {
    $("#sftp_box").addClass("d-none");
  }
})

$(document).on('change', '#password_expiry_check', function() {
  if ($(this).prop('checked')) {
    $("#password_expiry_box").removeClass("d-none");
  } else {
    $("#password_expiry_box").addClass("d-none");
  }
})

$(document).on('change', '#password_expiry_subusers_check', function() {
  if ($(this).prop('checked')) {
    $("#password_expiry_subusers_box").addClass("d-none");
  } else {
    $("#password_expiry_subusers_box").removeClass("d-none");
  }
})

$(document).on('change', '#final_payment_amount_check', function(event) {
  if ($(this).prop('checked')) {
    $(".final_payment_amount_field").removeClass("d-none");
  } else {
    $(".final_payment_amount_field").addClass("d-none");
  }
})

$(document).on('change', '.supplier_check_box', function(event) {
  if ($('.supplier_check_box:checked').length > 0) {
    $("#status-change-buttons").removeClass("d-none");
  } else {
    $("#status-change-buttons").addClass("d-none");
  }
})

$(document).on('change', '#select_all_suppliers', function(event) {
  if ($(this).prop('checked')) {
    $('.supplier_check_box').prop("checked", true);
    $("#status-change-buttons").removeClass("d-none");
  } else {
    $('.supplier_check_box').prop("checked", false);
    $("#status-change-buttons").addClass("d-none");
  }
})

$(document).ready(function () {
  $('#default-domain').click(function () {
    $('#configuration_domain_div').removeClass('d-none');
    $('#white_label_configuration_domain').val('fauree.com');
    $('#white_label_configuration_domain').prop('readonly', true);
  });

  $('#custom-domain').click(function () {
    $('#configuration_domain_div').removeClass('d-none');
    $('#white_label_configuration_domain').val('');
    $('#white_label_configuration_domain').prop("readonly", false);
  });
});

$(document).on('change', '#invoice-currency-field', function(event) {
  if ($('#invoice-currency-field').attr('value') != event.target.value) {
    $('#invoice-exchange-rate-field').removeClass('d-none')
  } else {
    $('#invoice-exchange-rate-field').addClass('d-none')
  }
})

$(document).on('change', '.invoice_amount', function(event) {
  let invoiceAmount = $('.invoice_amount')[0]["value"]
  if ($('#recalculate-epr').length > 0) {
    let url = $('#recalculate-epr')[0].href
    $('#recalculate-epr')[0].href = `${url}?invoice_amount=${invoiceAmount}`
  }

  let discountPercentage = $('.discount_percentage')[0]['value']
  let paymentDaysOne = $('.payment_days_one')[0]['value']
  let financingRulePercentage = $('.financing_percentage')[0]['value']

  let discountAmount = ((invoiceAmount * discountPercentage)/100).toFixed(2)
  let financingAmount = (((invoiceAmount - discountAmount) * (((paymentDaysOne/365) * financingRulePercentage) / 100))).toFixed(2)
  let eprAmount = (((invoiceAmount - discountAmount) - financingAmount)).toFixed(2)

  $('.discount_amount')[0]["value"] = discountAmount
  $('.financing_amount')[0]["value"] = financingAmount
  $('.epr_amount')[0]["value"] = eprAmount
  $("#total_amount_value").text(eprAmount);
});

  function updateCharacterCount() {
    const textArea = document.getElementById('remarks-input');
    const counter = document.getElementById('remarks-counter');
    const maxLength = parseInt(textArea.getAttribute('maxlength'));
    const currentLength = textArea.value.length;
  
    counter.textContent = `${currentLength}/${maxLength}`;
  }
  // Initial update
  updateCharacterCount()
  const remarksTextArea = document.getElementById('remarks-input');
  remarksTextArea.addEventListener('input', updateCharacterCount);
  
  function updateCharacterCounts() {
    const textArea = document.getElementById('reason-input');
    const counter = document.getElementById('reason-counter');
    const maxLength = parseInt(textArea.getAttribute('maxlength'));
    const currentLength = textArea.value.length;
  
    counter.textContent = `${currentLength}/${maxLength}`;
  }
  // Initial update
  updateCharacterCounts()
  const reasonTextArea = document.getElementById('reason-input');
  reasonTextArea.addEventListener('input', updateCharacterCounts);
 });

 $(document).on("change", "#supplier-select", function() {
  const currentUrl = new URL(window.location.href);
  currentUrl.searchParams.set("supplier_id", this.value);
  window.location.replace(currentUrl.toString());
});
