document.addEventListener("DOMContentLoaded", function() {
  const checkAllColumns = document.querySelectorAll('.check-all-column');
  function updateCheckAllState() {
    checkAllColumns.forEach(checkAll => {
      const columnIndex = checkAll.getAttribute('data-column-index');
      const checkboxes = document.querySelectorAll(`.column-${columnIndex}`);
      const allChecked = Array.from(checkboxes).every(checkbox => checkbox.checked || checkbox.disabled);
      checkAll.checked = allChecked;
    });
  }

  updateCheckAllState();

  checkAllColumns.forEach(checkAll => {
    checkAll.addEventListener('change', function() {
      const columnIndex = this.getAttribute('data-column-index');
      const checkboxes = document.querySelectorAll(`.column-${columnIndex}`);
      checkboxes.forEach(checkbox => {
        if (!checkbox.disabled) {
          checkbox.checked = this.checked;
        }
      });
    });
  });

  const allCheckboxes = document.querySelectorAll('.permission_check_box');
  allCheckboxes.forEach(checkbox => {
    checkbox.addEventListener('change', updateCheckAllState);
  });
});

document.addEventListener('DOMContentLoaded', function() {
  const form = document.querySelector('form');
  const customerSelect = document.getElementById('tags-input');
  const errorDiv = document.createElement('div');
  const defaultCheckbox = document.querySelector('.rules-check-box');
  errorDiv.classList.add('invalid-feedback');
  errorDiv.style.display = 'none';
  errorDiv.textContent = 'At least one associated customer must be selected.';
  customerSelect.parentNode.appendChild(errorDiv);

  form.addEventListener('submit', function(event) {
    if (!defaultCheckbox.checked && customerSelect.selectedOptions.length === 0) {
      event.preventDefault();
      customerSelect.classList.add('is-invalid');
      errorDiv.style.display = 'block';
    } else {
      customerSelect.classList.remove('is-invalid');
      errorDiv.style.display = 'none';
    }
  });
});