document.addEventListener("DOMContentLoaded", function () {
  const form = document.getElementById("supplier_location");
  const latitudeInput = document.getElementById("latitude");
  const longitudeInput = document.getElementById("longitude");
  const submitButton = document.getElementById("form_submit_button");

  function validateInput(event) {
    const input = event.target;
    const value = input.value;
    const regex = /^-?\d+(\.\d{1,8})?$/;
    const errorSpan = input.nextElementSibling;

    if (value && !regex.test(value)) {
      input.setCustomValidity("Invalid number format");
      errorSpan.textContent = "Please enter a valid number with up to 8 decimal places.";
      errorSpan.style.color = "red";
    } else {
      input.setCustomValidity("");
      errorSpan.textContent = "";
    }

    toggleSubmitButton();
  }

  function toggleSubmitButton() {
    if (latitudeInput.checkValidity() && longitudeInput.checkValidity()) {
      submitButton.disabled = false;
    } else {
      submitButton.disabled = true;
    }
  }

  latitudeInput.addEventListener("input", validateInput);
  longitudeInput.addEventListener("input", validateInput);

  toggleSubmitButton();

  form.addEventListener("submit", function (event) {
    if (!latitudeInput.checkValidity() || !longitudeInput.checkValidity()) {
      event.preventDefault();
      alert("Please fix the errors before submitting the form.");
    }
  });
});